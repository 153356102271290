import React, { useContext } from 'react';
import PlayVideoContent from '../Components/Main/PlayVideo/PlayVideoContent';
import Header from '../Components/Header'
import Footer from '../../SharedComponents/js/Footer'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import axios from 'axios'
import { Helmet } from 'react-helmet';
import { useStateValue } from "../../index";
import FingerLeftMenu from '../Components/Main/FingerLeftMenu';
import { Alert } from 'react-bootstrap';
const env = require('../../config.env')

const PlayVideo = () => {
  const [userDetails, dispatch] = useStateValue();
  const { state } = useLocation();
  const { showBoundary } = useErrorBoundary();
  const { id } = useParams()
  const [videoDetails, setVideoDetails] = useState()
  const [ret_msg, setret_msg] = useState('')
  const [has_msg, sethas_msg] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    let headers = {}
    if (state?.is_private)
      headers = { is_private: true }
    axios.post(`${env.SERVER_REQUEST_PATH}increaseView`, { video_id: id }, { headers: headers })
      .then((response) => {
      }).catch(err => {
        showBoundary(err)
        console.error(`Error: ${err}`)
      })
  }, [])
  useEffect(() => {
    if (id) {
      let headers = {}
      if (state?.is_private)
        headers = { is_private: true }
      axios.get(`${env.SERVER_REQUEST_PATH}videos/${id}`, { headers: headers })
        .then((response) => {
          let vid = response.data.data
          if (!vid.status)
            navigate("/private-content")
          setVideoDetails(vid)
        }).catch(err => {
          showBoundary(err)
          // console.error(`Error: ${err}`)
        })
    }
  }, [id])
  const showInfo = (msg) => {
    setret_msg(msg)
    sethas_msg(true)
    setTimeout(() => {
      hideInfo();
    }, 3000);
  }
  const hideInfo = () => {
    setret_msg('')
    sethas_msg(false)
  }
  return (
    <div className='d-flex flex-column PlayVideosContainer'>
      {/* {videoDetails &&  */}
      {/* <Helmet>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content={"ResearchTube.ai"} />
        <meta property="og:description" content="ResearchTube.ai" />
        <meta property="og:image" content="../%PUBLIC_URL%/logo2.png"
        {`data:image/*;base64,${videoDetails.thumb_url}`}
        />
        <meta property="og:url" content="https://researchtube.ai/video/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub3dkYXRlIjoiMjAyNC0wMy0yMFQyMTo0MDoxOC43MzNaIiwidmlkZW9JZCI6MSwiaWF0IjoxNzEwOTcwODE4fQ.7dw90fBRDLMZ3qdnUFMaHTyWiSQ6qtd1STKVnHuUQjU"
        // {`data:video/mp4;base64,${videoDetails.uri}`}
        />
      </Helmet> */}
      {/* } */}
      <div className='headercontainer'>
        <Header userdata={userDetails} catchErrors={(err) => {
          showBoundary(err)
        }} />
      </div>
      <div className='play-content'>

        <div className='top-m px-md-5 px-3 pb-3'>
          <PlayVideoContent
            is_private={state ? state.is_private : false}
            type={state ? state.type : ''}
            playlist_id={state ? state.playlist_id : ''}
            videoDetails={videoDetails}
            showInfo={(msg) => { showInfo(msg) }}
            catchErrors={(err) => {
              showBoundary(err)
            }} />
        </div>
      </div>
      <Alert className="info_alert" variant="primary" hidden={has_msg ? '' : 'hidden'}>
        <span>{ret_msg}</span>
      </Alert>

    </div>
  );
}
export default PlayVideo;
