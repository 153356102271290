import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import PasswordInput from '../../SharedComponents/js/PasswordInput'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// import { ErrorContext } from '../../SharedComponents/js/ErrorContext';
// import ErrorAlert from '../../SharedComponents/js/ErrorAlert';
import { useErrorBoundary } from "react-error-boundary";
import '../css/ResetPassword.css'
const env = require('../../config.env')
const ResetPassword = () => {
    // const { errors, seterrors } = useContext(ErrorContext);
    const { showBoundary } = useErrorBoundary();

    const navigate = useNavigate()
    const { id } = useParams()

    const [raiseError, setraiseError] = useState(false)
    const [errorMsg, seterrorMsg] = useState('')

    const onsubmit = (e) => {
        e.preventDefault()
        let pass = e.target.elements.Password.value
        let con_pass = e.target.elements.ConfirmPassword.value
        if (pass == con_pass)
            axios.post(env.SERVER_REQUEST_PATH + 'resetPassword', {
                password: pass, reset_token: id
            })
                .then((response) => {
                    let status = response.status
                    if (status == 200) {
                        restErrors()
                        navigate('/login')
                    }

                }).catch(err => {
                    // let new_errs = [...errors]
                    // new_errs.push(err)
                    // seterrors(new_errs);
                    handleErrors(err)
                    console.error(`Error: ${err}`)
                })
        else {
            showErrors("Passwords do not match.")
        }
    }
    const restErrors = () => {
        setraiseError(false)
        seterrorMsg("")
    }
    const showErrors = (err) => {
        setraiseError(true)
        seterrorMsg(err)
    }
    const handleErrors = (err) => {
        if (err.status == 500)
            showBoundary(err)
        else {
            showErrors(err.message)
        }
    }

    // const handleDismiss = (err) => {
    //     var array = errors
    //     var index = array.indexOf(err)
    //     if (index !== -1) {
    //         array.splice(index, 1);
    //         seterrors(array)
    //     }
    // }

    return (

        <div className='row w-100 h-100 m-0 position-absolute sign-up-container'>
            <div className='col-12 col-md-6 px-4 py-4 auth-container'>
                <div className="tab-content justify-content-between d-flex flex-column h-100 w-100 gap-3" id="pills-tabContent">
                    <a href="/" className='d-flex align-items-center logo-t-style'>
                        <img src=
                            {env.SERVER_MEDIA_PATH + 'pageLogo.png'}
                            className='auth-logo' />
                    </a>
                    <div className="auth-form-container mx-auto" id="loginformContainer">
                        <h1 className="auth-form-title text-center" id="loginHeaderTitle">Set new password</h1>

                        <div className="login-form auth-form-details mt-5" id="formDetails">
                            <form autocomplete="off" onSubmit={onsubmit}>
                                <PasswordInput label={"Password"} name="Password" placeholder={"Password"} />
                                <PasswordInput label={"Confirm Password"} name="ConfirmPassword"
                                    placeholder={"Confirm Password"} raiseError={raiseError} errorMsg={errorMsg} />
                                <Button type="submit" size='medium' className={"w-100 mt-4 mb-3"}>Save</Button>
                            </form>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="col-6 p-4 h-100 d-none d-md-flex">
                <div className='auth-img-container d-flex align-items-center justify-content-center h-100 w-100'>
                    <div className='content w-75'>
                        <h2>Built for researchers, by researchers.</h2>
                        <p>Be part of our academic community operated by artificial intelligence</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ResetPassword
