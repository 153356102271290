//import "../css/SignUpStep1.css"
import React, { useCallback, useEffect, useState } from 'react'
import FormInput from '../../SharedComponents/js/FormInput'
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import PasswordInput from '../../SharedComponents/js/PasswordInput';
const env = require('../../config.env')

const SignUpStep1 = (props) => {
    const { onChange } = props;
    const [usernameraiseError, setusernameraiseError] = useState(false)
    const [emailraiseError, setemailraiseError] = useState(false)
    const [username_errorMsg, setUsername_errorMsg] = useState('')
    const [email_errorMsg, setEmail_errorMsg] = useState('')
    const [is_UsernameChecked, set_is_UsernameChecked] = useState(false)
    const [is_EmailChecked, set_is_EmailChecked] = useState(false)

    const navigate = useNavigate()
    const navigateToLogin = () => {
        navigate(`/login`);
    }

    const nextstep = (event) => {
        event.preventDefault();
        axios.post(env.SERVER_REQUEST_PATH + 'checkUsername', { userName: event.target.elements.UserName.value })
            .then((response) => {
                let status = response.status
                if (status == 200) {

                    if (response.data.IsUnique) {
                        setusernameraiseError(false)
                        setUsername_errorMsg("")
                        set_is_UsernameChecked(true)
                    }
                    else {
                        setusernameraiseError(true)
                        set_is_UsernameChecked(false)
                        setUsername_errorMsg("UserName is already exists")
                    }
                }

            }).catch(err => {
                console.error(`Error: ${err}`)
            })

        axios.post(env.SERVER_REQUEST_PATH + 'checkEmail', { email: event.target.elements.Email.value })
            .then((response) => {
                let status = response.status
                if (status == 200) {
                    if (!response.data.IsAcademic) {
                        setemailraiseError(true)
                        setEmail_errorMsg("This is not an academic email")
                        set_is_EmailChecked(false)
                    }
                    else {
                        if (response.data.IsUnique) {
                            setemailraiseError(false)
                            setEmail_errorMsg("")
                            set_is_EmailChecked(true)
                        }
                        else {
                            setemailraiseError(true)
                            set_is_EmailChecked(false)
                            setEmail_errorMsg("Email is already exists")
                        }
                    }
                }

            }).catch(err => {
                console.error(`Error: ${err}`)
            })
    }
    useEffect(() => {
        if (is_UsernameChecked && is_EmailChecked)
            props.nextstep()
    }, [is_UsernameChecked, is_EmailChecked])
    return (
        <div id="step1Container">
            <form autocomplete="off" onSubmit={nextstep}>
                <h1 className="auth-form-title text-center" id="step1HeaderTitle">Create new account</h1>

                <div className='d-flex justify-content-center w-100'>
                    <p className='error-msg'
                        hidden={props.hasError ? '' : 'hidden'}>{props.errMsg}</p>
                </div>

                <div className="auth-form-details mt-2" id="formDetails">
                    <FormInput key='Name' name="Name" placeholder='Enter your full name..'
                        label='Name' onChange={onChange} required='required' />

                    <FormInput key='UserName' name="UserName" placeholder='Enter your username..'
                        raiseError={usernameraiseError} errorMsg={username_errorMsg}
                        label='Username' onChange={onChange} required='required' />

                    <FormInput type={"text"} name="Email" label="Email Address" placeholder="Enter your professional email address.."
                        raiseError={emailraiseError} errorMsg={email_errorMsg}
                        required='required' onChange={onChange}
                    />
                    <PasswordInput label={"Password"} name="Password" placeholder={"Password"}
                        onChange={onChange} />
                </div>

                <Button type="submit" className={"w-100" + " mb-3"}>Continue</Button>

                <p className="form-text-have-account mb-0">
                    Already have an account? <a href="#" id="haveAccount" onClick={navigateToLogin}> Login</a>
                </p>
            </form>
        </div>
    )
}

export default SignUpStep1