import React, { useContext, useCallback, useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import FormInput from '../../SharedComponents/js/FormInput'
import PasswordInput from '../../SharedComponents/js/PasswordInput'
import '../css/Login.css'
import '../css/Authentication.css'
// import { ErrorContext } from '../../SharedComponents/js/ErrorContext';
// import ErrorAlert from '../../SharedComponents/js/ErrorAlert';
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate, useLocation } from 'react-router-dom';
import { useStateValue } from "../../index";

const env = require('../../config.env')
const actionTypes = {
    SET_USER: "SET_USER"
};

const Login = () => {
    const navigate = useNavigate()
    const { showBoundary } = useErrorBoundary();
    const [hasError, setHasError] = useState(false)
    const [errMsg, setErrMsg] = useState()
    const [Email, setEmail] = useState('')
    const emailField = useRef(null)
    const passField = useRef(null)
    const [Password, setPassword] = useState('')
    const [userDetails, dispatch] = useStateValue();

    // const { errors, seterrors } = useContext(ErrorContext);

    useEffect(() => {
        let interval = setInterval(() => {
            if (emailField.current) {
                setEmail(emailField.current.value)
                //do the same for all autofilled fields
                if (passField.current)
                    setPassword(passField.current.value)

                clearInterval(interval)
            }
        }, 300)
    })
    const restErrors = () => {
        setHasError(false)
        setErrMsg('')
    }
    const showErrors = (err) => {
        setHasError(true)
        setErrMsg(err)
    }
    const handleErrors = (err) => {
        if (err.status == 500)
            showBoundary(err)
        else {
            showErrors(err.message)
        }
    }
    const navigateToSignUp = () => {
        navigate(`/sign-up`);
    }
    const navigateForget = () => {
        navigate(`/forget-password`)
    }
    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    const onInputChange = (event) => {
        let changedInput = event.target.name
        let changedValue = event.target.value

        switch (changedInput) {
            case 'Email':
                setEmail(changedValue)
                return
            case 'Password':
                setPassword(changedValue)
                return
        }

    }
    let optimisedHandleChange = useCallback(
        debounce(onInputChange, 500)
        ,
        []
    );
    const handleLogin = () => {
        let user = {
            email: Email, password: Password
        }
        axios.post(env.SERVER_REQUEST_PATH + 'login', user)
            .then((response) => {
                let status = response.status
                if (status == 200) {
                    restErrors()
                    localStorage.setItem("user-token", response.data.user_token)

                    dispatch({
                        type: actionTypes.SET_USER,
                        user: response.data.userData
                    })
                    // let save_per = []
                    // response.data.permissions.map((p) => {
                    //     save_per.push(p.page_name)
                    // })
                    // localStorage.setItem("user-permissions", save_per)
                    navigate('/home')

                }

            }).catch(err => {
                // let new_errs = [...errors]
                // new_errs.push(err)
                // seterrors(new_errs);
                handleErrors(err)
                console.error(`Error: ${err}`)
            })
    }
    const onsubmit = (event) => {

        event.preventDefault();
        handleLogin()

    }
    // const handleDismiss = (err) => {
    //     var array = errors
    //     var index = array.indexOf(err)
    //     if (index !== -1) {
    //         array.splice(index, 1);
    //         seterrors(array)
    //     }
    // }

    return (
        <div className='row w-100 h-100 m-0 position-absolute'>
            <div className='col-12 col-md-6 px-4 py-4 auth-container'>
                <div className="tab-content justify-content-between d-flex flex-column h-100 w-100 gap-3" id="pills-tabContent">
                    <a href="/" className='d-flex align-items-center logo-t-style'>
                        <img src=
                            {env.SERVER_MEDIA_PATH + 'pageLogo.png'}
                            className='auth-logo' />
                    </a>
                    <div className="auth-form-container mx-auto" id="loginformContainer">
                        <form autocomplete="off" onSubmit={onsubmit}>
                            <h1 className="auth-form-title text-center" id="loginHeaderTitle">Welcome back!</h1>
                            <div className='d-flex justify-content-center w-100'>
                                <p className='error-msg mx-auto my-0 text-center' hidden={hasError ? '' : 'hidden'}>{errMsg}
                                </p>
                            </div>

                            <div className="login-form auth-form-details mt-5" id="formDetails">

                                <FormInput inputRef={emailField} type={"text"} name="Email" label="Email Address" placeholder="Enter your professional email address.."
                                    required='required' onChange={optimisedHandleChange} />
                                <PasswordInput className='mb-2' spanPos={hasError} inputRef={passField} label={"Password"} name="Password" placeholder={"Password"}
                                    onChange={optimisedHandleChange} />
                                <p className="form-link-forgot-password">
                                    <a id="forgotPassword" href="#" onClick={navigateForget}>Forgot password?</a>
                                </p>
                            </div>

                            <Button type="submit" size='medium' className={"w-100 mt-3 mb-3"}>Login</Button>

                            <p className="form-text-have-account mb-0">
                                Don’t have an account? <a id="haveAccount" href="#" onClick={navigateToSignUp}> Sign up now</a>
                            </p>
                        </form>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="col-6 p-4 h-100 d-none d-md-flex">
                <div className='auth-img-container d-flex align-items-center justify-content-center h-100 w-100'>
                    <div className='content w-75'>
                        <h2>Built for researchers, by researchers.</h2>
                        <p>Be part of our academic community operated by artificial intelligence</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
