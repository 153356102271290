// import React from 'react'
// const FeedBackScroller = () => {
//     return (

//         <div className='d-flex flex-column justify-content-center align-items-center'>
//             <div className='scroll'>
//                 <div className='feed-container d-flex justify-centent-center align-items-center'>
//                     <div className='mx-4 pt-3 pb-4 rate-container'>
//                         <div className='mt-3 rate-header'>
//                             <div className='col-lg-10'><img className='rate-comma' src='/assets/rateComma.png' /></div>
//                             <div className='col-lg-auto'>
//                                 <p className='p-0 m-0 rate-name'>Jaydon Bator</p>
//                                 <p className='rate-place'>University of Toronto</p>
//                             </div>
//                         </div>
//                         <div className='rate-content'>
//                             <p className='sixth-sec-t'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>

//                         </div>
//                         <div className='rate-footer'>
//                             <img className='sixth-sec-img-style' src='/assets/rateimg.png' />
//                         </div>
//                     </div>
//                     <div className='mx-4 pt-3 pb-4 rate-container'>
//                         <div className='mt-3 rate-header'>
//                             <div className='col-lg-10'><img className='rate-comma' src='/assets/rateComma.png' /></div>
//                             <div className='col-lg-auto'>
//                                 <p className='p-0 m-0 rate-name'>Jaydon Bator</p>
//                                 <p className='rate-place'>University of Toronto</p>
//                             </div>
//                         </div>
//                         <div className='rate-content'>
//                             <p className='sixth-sec-t'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>

//                         </div>
//                         <div className='rate-footer'>
//                             <img className='sixth-sec-img-style' src='/assets/rateimg.png' />
//                         </div>
//                     </div>
//                     <div className='mx-4 pt-3 pb-4 rate-container'>
//                         <div className='mt-3 rate-header'>
//                             <div className='col-lg-10'><img className='rate-comma' src='/assets/rateComma.png' /></div>
//                             <div className='col-lg-auto'>
//                                 <p className='p-0 m-0 rate-name'>Jaydon Bator</p>
//                                 <p className='rate-place'>University of Toronto</p>
//                             </div>
//                         </div>
//                         <div className='rate-content'>
//                             <p className='sixth-sec-t'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>

//                         </div>
//                         <div className='rate-footer'>
//                             <img className='sixth-sec-img-style' src='/assets/rateimg.png' />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className='d-flex'>
//                 <div className=' me-2 elipse'></div>
//                 <div className='me-2 elipse'></div>
//                 <div className='me-2 elipse'></div>
//             </div>
//         </div>
//     )
// }

// export default FeedBackScroller

// import { useState } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
// import '../css/FeedBackScroller.css'

// function ControlledCarousel() {
//     const [index, setIndex] = useState(0);

//     const handleSelect = (selectedIndex) => {
//         setIndex(selectedIndex);
//     };

//     return (
//         <Carousel slide={false} className='feedback' activeIndex={index} onSelect={handleSelect}>
//             <Carousel.Item>
//                 <div className='px-4 text-left mx-4 pt-3 pb-4 rate-container'>
//                     <div className='mt-3 rate-header'>
//                         <div className='col-2 col-lg-10'><img className='rate-comma' src='/assets/rateComma.png' /></div>
//                         <div className='col-auto col-lg-auto'>
//                             <p className='p-0 m-0 rate-name'>Jaydon Bator</p>
//                             <p className='rate-place'>University of Toronto</p>
//                         </div>
//                     </div>
//                     <div className='rate-content'>
//                         <p className='sixth-sec-t'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
//                     </div>
//                     <div className='rate-footer'>
//                         <img className='sixth-sec-img-style' src='/assets/rateimg.png' />
//                     </div>
//                 </div>
//                 {/* <Carousel.Caption>
//                     <h3>First slide label</h3>
//                     <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
//                 </Carousel.Caption> */}
//             </Carousel.Item>
//             <Carousel.Item>
//                 <div className='px-4 text-left mx-4 pt-3 pb-4 rate-container'>
//                     <div className='mt-3 rate-header'>
//                         <div className='col-2 col-lg-10'><img className='rate-comma' src='/assets/rateComma.png' /></div>
//                         <div className='col-auto col-lg-auto'>
//                             <p className='p-0 m-0 rate-name'>Jaydon Bator</p>
//                             <p className='rate-place'>University of Toronto</p>
//                         </div>
//                     </div>
//                     <div className='rate-content'>
//                         <p className='sixth-sec-t'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
//                     </div>
//                     <div className='rate-footer'>
//                         <img className='sixth-sec-img-style' src='/assets/rateimg.png' />
//                     </div>
//                 </div>
//                 {/* <Carousel.Caption>
//                     <h3>Second slide label</h3>
//                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//                 </Carousel.Caption> */}
//             </Carousel.Item>
//             <Carousel.Item>
//                 <div className='px-4 text-left mx-4 pt-3 pb-4 rate-container'>
//                     <div className='mt-3 rate-header'>
//                         <div className='col-2 col-lg-10'><img className='rate-comma' src='/assets/rateComma.png' /></div>
//                         <div className='col-auto col-lg-auto'>
//                             <p className='p-0 m-0 rate-name'>Jaydon Bator</p>
//                             <p className='rate-place'>University of Toronto</p>
//                         </div>
//                     </div>
//                     <div className='rate-content'>
//                         <p className='sixth-sec-t'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
//                     </div>
//                     <div className='rate-footer'>
//                         <img className='sixth-sec-img-style' src='/assets/rateimg.png' />
//                     </div>
//                 </div>

//                 {/* <Carousel.Caption>
//                     <h3>Third slide label</h3>
//                     <p>
//                         Praesent commodo cursus magna, vel scelerisque nisl consectetur.
//                     </p>
//                 </Carousel.Caption> */}
//             </Carousel.Item>
//         </Carousel>
//     );
// }

// export default ControlledCarousel;

import React, { Component, useEffect, useState, useRef } from 'react';
import '../css/FeedBackScroller.css'
import $ from 'jquery';
import { FaCircle } from 'react-icons/fa'

function FeedBackScroller() {
    const containerRef = useRef(null);
    const scrollList = [1, 470, 970, 1490]
    const contentRef = useRef(null);
    const [active, setActive] = useState(1)

    const scroll = (index, s_num) => {

        //  if (direction == "r")
        //     contentRef.current.scrollLeft += s_num
        // else
        contentRef.current.scroll(s_num, { animated: true })
        // contentRef.current.scrollLeft = s_num
        setActive(index)
        // let far = $('.f-image-container').width() / 2 * direction;
        // let pos = $('.f-image-container').scrollLeft() + far;
        // $('.f-image-container').animate({ scrollLeft: pos }, 1000);
    }

    // render() {
    return <div className="">
        <div className="d-flex align-items-center f-wrapper" ref={containerRef}>
            {/* <a className="f-prev" onClick={() => scroll("l", -1)}>&#10094;</a> */}
            <div className="f-image-container" ref={contentRef}>
                <div className="w-25 p-2 m-2 cover-item f-img-style position-relative"></div>
                <div className={`${active == 1 ? '' : 'non-active'} p-2 m-2 cover-item f-img-style position-relative`}>
                    <div className={`rate-container ${active == 1 ? '' : 'non-active'}`}>
                        <div className={`mt-3 rate-header ${active == 1 ? '' : 'non-active'}`}>
                            <div className=' mx-3'>
                                <img className={`rate-comma ${active == 1 ? '' : 'non-active'}`} src='/assets/rateComma.png' /></div>
                            <div className=''>
                                <p className={`p-0 m-0 rate-name ${active == 1 ? '' : 'non-active'}`}>Prof. Anas Chaaban</p>
                                <p className={`rate-place ${active == 1 ? '' : 'non-active'}`}>
                                    University of British Columbia, Canada</p>
                            </div>
                        </div>
                        <div className='rate-content'>
                            <p className={`sixth-sec-t ${active == 1 ? '' : 'non-active'}`}>ResearchTube.Ai is an excellent idea for increasing research productivity and enhancing knowledge dissemination. It enables researchers to sift through many papers in video form and to decide which papers will be necessary reads for their research. It also provides the public with a convenient jargon-free way to access new research results. I will be happy to link to a ResearchTube.Ai video summary to papers on my research webpage.</p>
                        </div>
                        <div className='rate-footer'>
                            <img className={`sixth-sec-img-style ${active == 1 ? '' : 'non-active'}`} src='/assets/Anas.png' circular />
                        </div>
                    </div>
                </div>
                <div className={`${active == 2 ? '' : 'non-active'} p-2 m-2 cover-item f-img-style position-relative`}>
                    <div className={`rate-container ${active == 2 ? '' : 'non-active'}`}>
                        <div className={`mt-3 rate-header ${active == 2 ? '' : 'non-active'}`}>
                            <div className=' mx-3'><img className={`rate-comma ${active == 2 ? '' : 'non-active'}`} src='/assets/rateComma.png' /></div>
                            <div className=''>
                                <p className={`p-0 m-0 rate-name ${active == 2 ? '' : 'non-active'}`}>Prof. Anas Issa</p>
                                <p className={`rate-place ${active == 2 ? '' : 'non-active'}`}>United Arab Emirates University, UAE</p>
                            </div>
                        </div>
                        <div className='rate-content'>
                            <p className={`sixth-sec-t ${active == 2 ? '' : 'non-active'}`}>A unique method of presenting research articles in a media format. ResearchTube.Ai is the next YouTube for the academic community to share their research discoveries. The website's user interface is intuitive and visually appealing, facilitating easy navigation. The conversion process from a research article to media is efficient, resulting in compelling media outputs that effectively convey the intended message.</p>
                        </div>
                        <div className='rate-footer'>
                            <img className={`sixth-sec-img-style ${active == 2 ? '' : 'non-active'}`} src='/assets/isaa.png' />
                        </div>
                    </div>
                </div>
                {/* <div className={`${active == 3 ? '' : 'non-active'} p-2 m-2 cover-item f-img-style position-relative`}>
                    <div className={`rate-container ${active == 3 ? '' : 'non-active'}`}>
                        <div className={`mt-3 rate-header ${active == 3 ? '' : 'non-active'}`}>
                            <div className=' mx-3'><img className={`rate-comma ${active == 3 ? '' : 'non-active'}`} src='/assets/rateComma.png' /></div>
                            <div className=''>
                                <p className={`p-0 m-0 rate-name ${active == 3 ? '' : 'non-active'}`}>3Jaydon Bator</p>
                                <p className={`rate-place ${active == 3 ? '' : 'non-active'}`}>University of Toronto</p>
                            </div>
                        </div>
                        <div className='rate-content'>
                            <p className={`sixth-sec-t ${active == 3 ? '' : 'non-active'}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        </div>
                        <div className='rate-footer'>
                            <img className={`sixth-sec-img-style ${active == 3 ? '' : 'non-active'}`} src='/assets/rateimg.png' />
                        </div>
                    </div>
                </div>
                <div className={`${active == 4 ? '' : 'non-active'} p-2 m-2 cover-item f-img-style position-relative`}>
                    <div className={`rate-container ${active == 4 ? '' : 'non-active'}`}>
                        <div className={`mt-3 rate-header ${active == 4 ? '' : 'non-active'}`}>
                            <div className=' mx-3'><img className={`rate-comma ${active == 4 ? '' : 'non-active'}`} src='/assets/rateComma.png' /></div>
                            <div className=''>
                                <p className={`p-0 m-0 rate-name ${active == 4 ? '' : 'non-active'}`}>4Jaydon Bator</p>
                                <p className={`rate-place ${active == 4 ? '' : 'non-active'}`}>University of Toronto</p>
                            </div>
                        </div>
                        <div className='rate-content'>
                            <p className={`sixth-sec-t ${active == 4 ? '' : 'non-active'}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                        </div>
                        <div className='rate-footer'>
                            <img className={`sixth-sec-img-style ${active == 4 ? '' : 'non-active'}`} src='/assets/rateimg.png' />
                        </div>
                    </div>
                </div> */}
                <div className="p-2 m-2 cover-item f-img-style position-relative"></div>
            </div>

            {/* <a className="f-next" onClick={() => scroll("r", 1)}>&#10095;</a> */}
        </div>
        <div className='scroller d-flex justify-content-center'>
            <a className="ms-3" onClick={() => scroll(1, 1)}><FaCircle color={active == 1 ? '#B39DBF' : '#F3F3F3'} /></a>
            <a className="ms-3" onClick={() => scroll(2, 470)}><FaCircle color={active == 2 ? '#B39DBF' : '#F3F3F3'} /></a>
            {/* <a className="ms-3" onClick={() => scroll(3, 970)}><FaCircle color={active == 3 ? '#B39DBF' : '#F3F3F3'} /></a> */}
            {/* <a className="ms-3" onClick={() => scroll(4, 1490)}><FaCircle color={active == 4 ? '#B39DBF' : '#F3F3F3'} /></a> */}
        </div>
    </div>
}
export default FeedBackScroller