import React, { useState, useCallback } from 'react'
import VideoViewer from '../../SharedComponents/js/VideoViewer'
import Form from 'react-bootstrap/Form'
import '../css/Publish.css'
import { Button } from 'semantic-ui-react'
import TagUsers from '../../Main/Components/TagUsers'
import { Alert } from 'react-bootstrap'

const Publish = (props) => {
    const [descr, setdescr] = useState()
    const [taggedUsers, settaggedUsers] = useState()
    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    const onInputChange = (e) => {
        let changedValue = e.target.value
        setdescr(changedValue)
    }
    let optimisedHandleChange = useCallback(
        debounce(onInputChange, 500)
        ,
        []
    );
    if (props.VideoData)
        return (
            <div className='ms-5 mt-3'>
                <div className='row m-0'>
                    <div className='col-md-12 mb-3'><h4>{props.is_author ? 'Publish your video.' : 'Save your video.'}</h4></div>
                </div>
                <div className='mx-0 row publish_wrapper'>
                    <div className='col-md-6'>
                        <VideoViewer
                            subtitle={props.VideoData.subtitle ? props.VideoData.subtitle : ''}
                            type={`${props.viewer_type == "tokens" ? "play" : "publish"}`}
                            thumb={props.VideoData.thumb_img} link={props.VideoData.video_path} />
                    </div>

                    <div className='col-md-6'>
                        <Form autocomplete="off" onSubmit={(e) => {
                            e.preventDefault()
                            props.saveVideo(props.doc_title, descr,
                                taggedUsers
                            )
                        }}>
                            <div className='d-flex flex-column'> <label className="form-label input-label">Title</label>
                                <p>{props.doc_title}</p>
                            </div>
                            <label className="form-label input-label">Description</label>
                            <div className='py-0 my-0 d-flex justify-content-between align-items-start'>
                                <p className='my-0 desc-style'>Write a few sentences about your video</p>
                                <Alert className='space-around' key='info' variant='info'>
                                    <p className='my-0 fw-bold desc-info'>Max No. of charactrers is 500</p>
                                </Alert>
                            </div>
                            <Form.Control as="textarea"
                                onKeyDown={(event) => {
                                    event.stopPropagation();
                                }}
                                onChange={onInputChange}
                                name="descr"
                                rows={5}
                                className='w-100' />
                            {(props.is_author || props.is_share) &&
                                <>
                                    <label className='mt-5 mb-0 form-label input-label'>Tag your friends</label>
                                    <TagUsers getTaggedUsers={(selected_friends) => settaggedUsers(selected_friends)} /></>}
                            <div className=' mt-2 d-flex justify-content-end w-100'>
                                <Button
                                    disabled={props.stop_process ? 'disabled' : ''}
                                    className='w-25 btn btn-gen btn-primary ms-3' type='submit' >
                                    {props.is_author ? 'Publish' : 'Save'}  </Button>
                            </div>
                        </Form>
                        <div className='col-md-12 w-100 mt-5 d-flex align-items-center'>
                        </div>
                    </div>
                </div>
            </div >
        )
}

export default Publish
