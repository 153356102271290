import '../../../css/VideoCard.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { BsBookmark, BsBookmarkFill, BsChat, BsCheck, BsEye, BsHeart } from 'react-icons/bs';
import { BiLike } from 'react-icons/bi';
import VideoPoster from '../../VideoPoster';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { FaUserCircle } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { MdOutlinePublishedWithChanges } from 'react-icons/md';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const env = require('../../../../config.env')
const helper = require('../../../../utilities/helpers')

function VideoCard(props) {
    const [video_id, setVideo_id] = useState(props.id)
    const navigate = useNavigate()
    const [saved, setsaved] = useState(false)
    const [ispending, setIspending] = useState(false)
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (ispending && props.pending_status == "gen") {
            const interval = setInterval(() => {
                setProgress((prev) => {
                    if (prev < 80) {
                        return prev + 5; // Increment progress  
                    } else {
                        clearInterval(interval); // Clear interval when max is reached  
                        return prev;
                    }
                });
            }, 1000); // Increment every 1 second  

            return () => clearInterval(interval);
        } // Cleanup on component unmount  
    }, [ispending]);
    useEffect(() => {
        setsaved(props.saved ? props.saved > 0 ? true : false : false)
    }, [props.saved])

    useEffect(() => {
        setIspending(props.is_pending ? props.is_pending > 0 ? true : false : false)
    }, [props.is_pending])

    const onSave = () => {
        let headers = {}
        if (props.is_private)
            headers = { is_private: true }
        setsaved((prev) => !prev)
        axios.post(`${env.SERVER_REQUEST_PATH}save`, { video_id: video_id, saved: !saved }, { headers: headers })
            .then((response) => {
                // props.refreshVideos(props.type)
            }).catch(err => {
                setsaved((prev) => !prev)
                props.catchErrors(err)
                console.error(`Error: ${err}`)
            })

    }
    return (
        <div className="video-card col-lg-3 col-md-4 col-12">
            <div className="position-relative thumbnail">
                <VideoPoster ispending={ispending} is_private={props.is_private}
                    video_id={props.id} v_len={props.length} thumb={props.thumb_url} />

                {ispending &&
                    <div className='publish-placeholder'>
                        {props.pending_status == "pub" && <span className='pub-text-placeholder'>Ready to publish</span>}
                        {props.pending_status == "gen" && <CircularProgressbar strokeWidth={5} value={progress} text={`${progress}%`} />}
                    </div>
                }
            </div>

            <div className='d-flex flex-column w-100 mt-3'>

                <span className="w-100 video-date">
                    {helper.getTimeSinceCreation(props.creation_date)}
                </span>

                <div class="videoTitle">
                    <NavLink title={props.VideoTitle}
                        to={`/video/${props.id}`}
                        state={{ is_private: props.is_private }}>
                        {props.VideoTitle}
                    </NavLink>
                </div>

                <span className="w-100 video-category">
                    {props.category}
                </span>

                <div className='d-flex gap-2 align-items-center link-pointer user-area'
                    onClick={() => {
                        navigate(`/profile/${props.username}`, { state: props.user_id })
                    }}>
                    {props.profile_img &&
                        <div className='user-image'>
                            <img src={env.SERVER_REQUEST_PATH + props.profile_img} circular="true" />
                        </div>
                    }
                    {!props.profile_img && <FaUserCircle size={30} color='6e757c' />}

                    <div className='d-flex flex-column'>
                        <span className='user-name'>
                            {props.firstname + ' ' + (props.lastname ? props.lastname : "")}
                        </span>
                        <span className='user-university'>{props.affiliation}</span>
                    </div>
                </div>

                <div className='d-flex flex-row stats-font justify-content-between align-items-center w-100 mt-2'>
                    <div className='d-flex align-items-center'>
                        <span className="my-0 me-2"><BsChat className='me-1' color='#999999' />{helper.kFormatter(props.no_comments)}</span>
                        <span className="my-0 me-2"><BiLike className='me-1' color='#999999' />{helper.kFormatter(props.no_likes)}</span>
                        <span className="my-0 me-2"><BsEye className='me-1' color='#999999' />{helper.kFormatter(props.no_views)}</span>
                    </div>

                    <div>

                        {!saved && !ispending &&
                            <Button className='py-1 card-save-btn' variant="outline-primary" size="sm" onClick={onSave}><BsHeart color='#5644DE' /> Save</Button>
                        }
                        {saved && !ispending > 0 &&
                            <Button className='py-1 card-save-btn' variant="primary" size="sm" onClick={onSave}><BsCheck color='#fff' /> Saved</Button>
                        }
                        {ispending &&
                            <Button className='py-1 card-save-btn' variant="outline-primary" size="sm"
                                onClick={() => {
                                    setIspending(true)
                                    props.publish()
                                }}><MdOutlinePublishedWithChanges color='#5644DE' /> Publish</Button>
                        }
                    </div>
                </div>

            </div>
        </div >
    );
}

export default VideoCard;