
import React, { Component, useEffect, useRef, useState } from 'react';
import '../css/ImageScroller.css'
import $ from 'jquery';
import { FaImage } from 'react-icons/fa';
const env = require('../../config.env')

const ImageScroller = (props) => {
    const [imgs, set_imgs] = useState([])
    const [id, set_id] = useState()
    const [isSelected, setIsSelected] = useState(0)
    const [isSelected_trans, setIsSelected_trans] = useState()
    const first_image_ref = useRef()
    const scrollContainer_ref = useRef()
    const [is_scroll, set_is_scroll] = useState(true)

    useEffect(() => {
        if (imgs.length > 0) {
            // if (is_scroll)
            // if (props.selectedImage === 0 && isSelected === imgs.length - 1)
            // scrollToElement()
            // first_image_ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            // else  if (props.selectedImage > isSelected)
            //     scroll(1)
            // else if (props.selectedImage < isSelected)
            // scrollToElement()
            //     scroll(-1)
            setIsSelected(props.selectedImage)
            props.imageClicked(props.selectedImage, false)
        }
    }, [props.selectedImage])
    useEffect(() => {
        scrollToElement()
    }, [isSelected])
    useEffect(() => {
        if (props.scroll)
            set_is_scroll(props.scroll)
    }, [props.scroll])
    useEffect(() => {
        if (props.imgs)
            set_imgs(props.imgs)
        set_id(props.id)
    }, [props.imgs])
    const scroll = (direction) => {
        if (scrollContainer_ref.current) {
            let container = $(scrollContainer_ref.current);
            let far = container.width() / (imgs.length / 2.1) * direction;
            let pos = container.scrollLeft() + far;
            container.animate({ scrollLeft: pos }, 1000);
        }
    }
    const remove = (e, item) => {
        props.removeItem(id, item)
    }
    const scrollToElement = () => {
        if (scrollContainer_ref.current && first_image_ref.current) {
            first_image_ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: "center", });

            // // const container = scrollContainer_ref.current;
            // // const target = first_image_ref.current;

            // // // Calculate the position to scroll to
            // // const containerRect = container.getBoundingClientRect();
            // // const targetRect = target.getBoundingClientRect();

            // // // Scroll the container horizontally to the target element
            // // container.scrollBy({
            // //     left: targetRect.left - containerRect.left,
            // //     behavior: 'smooth',
            // // });
            // const scrollDiv = scrollContainer_ref.current;
            // const firstElement = first_image_ref.current;

            // if (scrollDiv && firstElement) {
            //     const scrollDivRect = scrollDiv.getBoundingClientRect();
            //     const firstElementRect = firstElement.getBoundingClientRect();
            //     const scrollDivScrollLeft = scrollDiv.scrollLeft;
            //     if (firstElementRect.left > scrollDivRect.left) {
            //         scrollDiv.scrollTo({
            //             left: firstElementRect.left,
            //             behavior: 'smooth'
            //         });
            //     }

            //     else {
            //         if (firstElementRect.left < scrollDivRect.left) {
            //             scrollDiv.scrollTo({
            //                 left: scrollDivRect.left,
            //                 behavior: 'smooth'
            //             });
            //         }
            //     }
            // }

        }
    }
    return <div className="row main m-0">

        <div className="wrapper">
            {/* <span className="prev" onClick={(e) => scroll(-1)}>&#10094;</span> */}
            <div ref={scrollContainer_ref} className="m-0 px-0 py-1 image-container">
                <div className='d-flex align-items-center col-md-1 col-2 start-trans-container'>
                    <div className='d-flex align-items-center justify-content-start w-100 h-100'>
                        <div role='button'
                            className={`${isSelected_trans == 0 ? 'selected' : ''} start trans-container`}
                            onClick={() => {
                                setIsSelected_trans(0)
                                props.trans_clicked(0)
                            }}>
                            <div className={`${isSelected_trans == 0 ? 'selected' : ''} trans-style`}></div>
                        </div>
                    </div>
                </div>
                {imgs.map((img, index) => {
                    return (
                        <div className={`d-flex align-items-center mx-2 col-md-2 col-4 `}>
                            <div ref={index == isSelected ? first_image_ref : null}
                                className={`p-1 cover-item sec7-img-style ${isSelected == index ? 'selected' : ''} position-relative`}>
                                {props.is_urlPath && img.url && <img src={img.url}
                                    onClick={() => {
                                        setIsSelected(index)
                                        set_is_scroll(false)
                                        props.imageClicked(index)
                                    }}
                                />}
                                {!props.is_urlPath && <img name={img.fig_name}
                                    src={`data:image/*;base64,${img.fig_path}`} />}
                                {props.is_urlPath && !img.url && <FaImage size={80} color='#eaecef'
                                    onClick={() => {
                                        set_is_scroll(false)
                                        props.imageClicked(index)
                                    }}
                                />}
                                {/* <a className="remove-image" href="#"
                                onClick={((e) => remove(e, img.fig_name))}
                            >&#8211;</a> */}
                            </div>
                            <div role='button'
                                className={`${isSelected_trans == index + 1 ? 'selected' : ''} trans-container ms-3`}
                                onClick={() => {
                                    setIsSelected_trans(index + 1)
                                    props.trans_clicked(index + 1)
                                }}>
                                <div className={`${isSelected_trans == index + 1 ? 'selected' : ''} trans-style`}></div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* <span className="next" onClick={(e) => {
                scroll(1)
            }
            }>&#10095;</span> */}
        </div>
    </div>
}

export default ImageScroller 