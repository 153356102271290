import React, { useEffect, useState } from 'react'
import { BsArrowUpRight, BsCheck } from 'react-icons/bs'
import dateFormat from 'dateformat';
import '../../../css/VideoDetails.css'
import { useNavigate } from 'react-router-dom'
import { FaUserCircle } from 'react-icons/fa';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { PropagateLoader } from 'react-spinners';

const helpers = require('../../../../utilities/helpers')
const env = require('../../../../config.env')

const VideoDetails = ({ isLoading, playlist_loading, share_loading, is_private, catchErrors, showInfo,
    videoDetails, videoContent, setFollowed }) => {
    const [vidpPath, setVideoPath] = useState('')
    const [loaded_authors, set_loadedauthors] = useState([])
    const [unloaded_authors, set_unloadedauthors] = useState([])
    const [last_author, set_last_author] = useState()
    const [is_desc_loaded, setis_Desc_loaded] = useState(false)
    const [is_title_loaded, setis_title_loaded] = useState(false)
    const [is_author_loaded, set_isAuthor_loaded] = useState(false)
    const [video_data, setVideo_data] = useState()
    const [subjects, setsubjects] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        setVideo_data(videoDetails)
    }, [videoDetails])

    useEffect(() => {
        if (video_data) {
            setVideoPath(video_data.uri)
            video_data.desc = video_data?.desc?.replace(/(?:\r\n|\r|\n)/g, ' \r\n ')
            // videoDetails.title = "Cell invasion in digital microfluidic microgel"
            // videoDetails.desc = "wsdfc wead dscxerwdfs wdsc wdscx wdscx wsdvc wdvscrgb be debfd bed c fdc edv ed efdc dfvc gdfvc e dc fd vdfv df deffd "
            let loaded_authors = []
            let unloaded_author = []
            let last_author
            if (video_data.authors) {
                let parts = video_data.authors.split(',')
                parts.map((p, index) => {
                    if (index <= 0)
                        loaded_authors.push(p)
                    else if (index > 0 && index < parts.length - 1)
                        unloaded_author.push(p)
                })
                last_author = parts[parts.length - 1]
                set_loadedauthors(loaded_authors)
                set_unloadedauthors(unloaded_author)
                set_last_author(last_author)
            }
            let subs = []
            if (video_data.docsubjects) {
                let parts = video_data.docsubjects.split(',')
                parts.map((p) => {
                    subs.push(p)
                })
                setsubjects(subs)
            }

        }
    }, [video_data])
    const unfollow = (e) => {
        const user_id = video_data?.user_id
        let updatedData = { ...video_data, isFollowing: 0 }
        setVideo_data(updatedData)
        axios.get(`${env.SERVER_REQUEST_PATH}unfollow/${user_id ? user_id : -1}`)
            .catch(err => {
                updatedData = { ...video_data, isFollowing: 1 }
                setVideo_data(updatedData)
                catchErrors(err)
            })
    }
    const follow = (e) => {
        const user_id = video_data?.user_id
        let updatedData = { ...video_data, isFollowing: 1 }
        setVideo_data(updatedData)
        axios.get(`${env.SERVER_REQUEST_PATH}follow/${user_id ? user_id : -1}`)
            .catch(err => {
                updatedData = { ...video_data, isFollowing: 0 }
                setVideo_data(updatedData)
                catchErrors(err)
            })
    }

    return (

        <div className='video-details-container d-flex flex-column justify-content-between h-100'>
            {videoDetails && <div className='py-3'>
                <div className='d-flex flex-column gap-2 py-2 px-4'>
                    <div className='d-flex justify-content-between w-100'>
                        <span className='generated-by'>Generated by</span>
                        <span className='generation-date'>
                            {dateFormat(video_data?.creation_date, "mmm dd, yyyy")}
                        </span>
                    </div>

                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex gap-2 align-items-center link-pointer w-75'
                            onClick={() => {
                                navigate(`/profile/${video_data?.username}`, { state: video_data?.user_id })
                            }}>
                            {video_data?.profile_img &&
                                <div className='user-image'>
                                    <img src={env.SERVER_REQUEST_PATH + video_data.profile_img} circular="true" />
                                </div>
                            }
                            {!video_data?.profile_img && <FaUserCircle size={30} color='6e757c' />}

                            <div className='d-flex flex-column'>
                                <span className='user-name'>
                                    {video_data?.firstname + ' ' + (video_data?.lastname ? video_data?.lastname : "")}
                                </span>
                                <span className='user-university'>{video_data?.affiliation}</span>
                            </div>
                        </div>
                        {!video_data?.ismyvideo &&
                            <div className='d-flex justify-content-end align-items-end'>
                                {video_data?.isFollowing > 0 && <Button
                                    variant="primary" size="sm"
                                    onClick={unfollow}>Following
                                    <BsCheck size={20}></BsCheck>
                                </Button>
                                }

                                {video_data?.isFollowing == 0 && <Button
                                    size="sm" variant="outline-primary"
                                    onClick={follow} >Follow
                                </Button>}
                            </div>
                        }
                    </div>
                </div>

                <div className='video-details-content d-flex flex-column gap-2 h-100 px-4 mt-1'>
                    <div className='w-100'>
                        <div className={`title-container ${is_title_loaded ? '' : 'unloaded'}`}>
                            <h5 className={`video-title ${is_title_loaded ? '' : 'unloaded'} m-0`}>
                                {video_data?.title}
                                {is_title_loaded && video_data?.title.length > 85 && <span className='show_link ms-1' role='button' onClick={() => setis_title_loaded(false)}>show less</span>}
                            </h5>
                            {!is_title_loaded && video_data?.title.length > 85 && <span className='show_link' role='button' onClick={() => setis_title_loaded(true)}>show more</span>}
                        </div>

                    </div>

                    {video_data?.category &&
                        <div className='d-flex w-100'>
                            <p className='video-interest m-0'>{video_data?.category}</p>
                        </div>
                    }

                    {video_data?.desc &&
                        <div className='d-flex w-100 video-desc'>
                            <p className={`${is_desc_loaded ? 'loaded' : 'unloaded'} w-100 m-0`}>{video_data?.desc}
                                {is_desc_loaded && video_data?.desc.length > 60 && <span className='show_link' role='button' onClick={() => setis_Desc_loaded(false)}>show less</span>}
                            </p>
                            {!is_desc_loaded && video_data?.desc.length > 60 && <span className='show_link' role='button' onClick={() => setis_Desc_loaded(true)}>show more</span>}
                        </div>
                    }

                    <div className='row m-0 p-0'>
                        {video_data?.publicationDate &&
                            <div className='d-flex flex-column col-12 col-md-6 p-0'>
                                <p className='info-title m-0 mb-2'>Published in</p>
                                <p className='info-content m-0'>  {dateFormat(video_data?.publicationDate, "mmm dd, yyyy")}</p>
                            </div>
                        }

                        {video_data?.doctype &&
                            <div className='d-flex flex-column col-12 col-md-6 p-0'>
                                <p className='info-title m-0 mb-2'>Artical type</p>
                                <p className='info-content m-0'> {video_data?.doctype}</p>
                            </div>
                        }
                    </div>

                    {video_data?.authors && video_data?.authors.length > 0 &&
                        <div className='d-flex flex-column w-100 mt-2'>
                            <p className='info-title m-0 mb-2'>Authors</p>
                            <div className='authors w-100 d-flex flex-wrap align-items-center'>
                                {loaded_authors.length > 0 &&
                                    loaded_authors.map((author, index) => {
                                        if (author.user_id) {
                                            return (
                                                <span className='info-content me-2'
                                                    onClick={() => {
                                                        navigate(`/profile/${author?.username}`, { state: author?.user_id })
                                                    }}>
                                                    {author}
                                                    <BsArrowUpRight size={10} color='#5644DE' />
                                                </span>
                                            )
                                        }
                                        else {
                                            return (
                                                <span className='info-content me-2'>{author}</span>
                                            )
                                        }
                                    })
                                }
                                {is_author_loaded && unloaded_authors.length > 0 &&
                                    unloaded_authors.map((author, index) => {
                                        if (author.user_id) {
                                            return (
                                                <span className='info-content me-2'
                                                    onClick={() => {
                                                        navigate(`/profile/${author?.username}`, { state: author?.user_id })
                                                    }}>
                                                    {author}
                                                    <BsArrowUpRight size={10} color='#5644DE' />
                                                </span>
                                            )
                                        }
                                        else {
                                            return (
                                                <span className='info-content me-2'>{author}</span>
                                            )
                                        }
                                    })
                                }
                                {!is_author_loaded && unloaded_authors.length > 0 &&
                                    <span className='me-2 show_link' role='button' onClick={() => set_isAuthor_loaded(true)}>[..show all]</span>}
                                <span className='me-2 info-content'>{last_author}</span>
                                {is_author_loaded &&
                                    <span className='show_link' role='button' onClick={() => set_isAuthor_loaded(false)}>show less</span>}

                            </div>
                        </div>
                    }

                    {video_data?.docpublisher &&
                        <div className='d-flex flex-column w-100 mt-2'>
                            <p className='info-title m-0 mb-2'>Publisher</p>
                            <p className='info-content m-0'>{video_data?.docpublisher}</p>
                        </div>
                    }

                    {video_data?.docjournaltitle &&
                        <div className='d-flex flex-column w-100 mt-2'>
                            <p className='info-title m-0 mb-2'>Journal</p>
                            <p className='info-content m-0'>{video_data?.docjournaltitle}</p>
                        </div>
                    }
                    {/* <div className='row mt-2 mb-3'>
                    <div className='mt-2 vdescription'>

                        {subjects.length > 0 && <div className='sub-badge mt-1'>
                            {subjects.length > 0 && subjects.map((subject, index) => {
                                return (
                                    <Badge key={"subject-" + index} text="dark" className='ms-1' >{subject}</Badge>
                                )
                            })}
                        </div>}
                    </div>
                </div> */}
                </div>
            </div>
            }

            {videoDetails && <div className='d-flex w-100 px-4 py-3'>
                <a className='text-decoration-none view-doc' target="_blank"
                    href={video_data?.paper_link}> View document
                    <BsArrowUpRight className='ms-2' size={10} color='#5644DE' />
                </a>
            </div>
            }
            {!videoDetails && <div className='d-flex align-items-center justify-content-center w-100 h-100'><PropagateLoader color="#B9AFF7" /></div>}
        </div>
    )
}

export default VideoDetails
