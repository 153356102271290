import React, { useContext, useReducer } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import axios from 'axios'
import AppErrorBoundary from './SharedComponents/js/AppErrorBoundary';
import { getCurrentPage, is_allowedPage } from './utilities/helpers';
const env = require('./config.env')
axios.interceptors.request.use(
  config => {
    let req = config.url.split("/").pop()
    // if (config.url.includes('shutterstock'))
    if (config.url.includes('pexels') || config.url.includes('shutterstock'))
      return config

    let is_allowed = is_allowedPage()
    let found = env.allowedReq.find((item) => item == req)
    if (!found) {
      let data = config.url.split("/")
      let pub_req = data[data.length - 1]
      let pub_req2 = data[data.length - 2]
      let pub_found = env.publicReq.find((item) => item == pub_req)
      let pub_found2 = env.publicReq.find((item) => item == pub_req2)

      const token = localStorage.getItem("user-token")
      if (token) {
        config.headers['user_token'] = token
        if (!is_allowed) {
          let page_name = getCurrentPage()
          config.headers['page_name'] = page_name
        }
      }
      else if (!token && !pub_found && !pub_found2) {
        window.location.href = '/'
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)
axios.interceptors.response.use(
  response => {
    if (response.status == 401) {
      window.location.href = '/'
      window.history.replaceState(null, null, "/");
    }
    return response;
  }, (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status == 401) {
      window.location.href = '/'
      window.history.replaceState(null, null, "/");

      return error.response;
    }
    else if (error.response.status == 403) {
      window.location.href = '/coming-soon'
      window.history.replaceState(null, null, "/coming-soon");

      return error.response;
    }
    else {
      error.code = error.response.status
      if (error.response.data.msg)
        error.message = error.response.data.msg
      else
        error.message = error.response.data.message

      return Promise.reject(error);
    }
  });


const initialState = {
  user: null
};

const actionTypes = {
  SET_USER: "SET_USER"
};

const reducer = (userDetails, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...userDetails,
        user: action.user
      };

    default:
      return userDetails;
  }
};
function handleGlobalError(message, source, lineno, colno, error) {
  return true;
}

window.onerror = handleGlobalError;
window.addEventListener('unhandledrejection', function (event) {
  return true;
});
const StateContext = React.createContext();

const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AppErrorBoundary>
      <StateProvider initialState={initialState} reducer={reducer}>
        <App />
      </StateProvider>
    </AppErrorBoundary>
  </BrowserRouter>

  // </React.StrictMode>
);
reportWebVitals();
