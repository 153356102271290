import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../../css/Search.css'
const Search = () => {
    const navigate = useNavigate()
    const search = (e) => {
        if (e.key === 'Enter') {
            navigate(`/search?t=1&s=${e.target.value}`)
        }
    }
    return (
        <form autocomplete="off" className="search-form col-12 col-lg-auto" >
            <input type="search" onKeyDown={search}
                className="form-control" name="search_input" placeholder="Search for Content, Authors, Materials, ..etc" aria-label="Search" />
        </form>
    )
}

export default Search
