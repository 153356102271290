import Konva from "konva";
import React, { useEffect, useRef, useState } from "react";
import axios from 'axios'
// import { Whammy } from "whammy";
import { Image, Layer, Stage, Text, Rect, Transformer, Label, Tag, Group } from "react-konva";
import { TextEditor } from "./TextEditor";
import { FaPlayCircle, FaPlusCircle, FaSearch, FaStopCircle } from "react-icons/fa";
import SearchBackground from "./SearchBackground";
import { HiOutlineSearchCircle, HiSearchCircle } from "react-icons/hi";
import { useErrorBoundary } from "react-error-boundary";
import { getVideos_pex, getVideos_shuttersk } from "../../utilities/helpers";
import { BiEdit } from "react-icons/bi";
import { IoIosSearch } from "react-icons/io";
import { useImage } from "react-konva-utils";
import { PropagateLoader } from "react-spinners";

const env = require('../../config.env')

const Canvas = ({ doc_id, text, text2, setText, textColor, textBackground, textBackground2, textBorderColor, textBorderWidth,
    borderRadius, canvas_index, keyword, videoIsTitle, subject, interest, parentContainer_width, canvas_Height,
    textFont, fontSize, videoOpacity, backgroundOpacity, is_play, setFontSize, getUniqueFrame, updateFrameText,
    backround_details, hide_thumbnails, setFramesData, sendnewVideo, pauseAllFrames }) => {
    const videoRef = useRef(null);
    const stageRef = useRef(null);
    const canvas_container_ref = useRef()
    const [canvas_video_loaded, set_canvas_video_loaded] = useState(false)
    const [is_dataLoading, set_isDataLoading] = useState(false)
    const textNodeRef = useRef(null);
    const text2NodeRef = useRef(null);
    const tagRef = useRef(null)
    const last_line_text1_ref = useRef()
    const last_line_text2_ref = useRef()
    const desiredResolution_width = 1920
    const desiredResolution_height = 1080
    const [fontSize2, setfontSize2] = useState(fontSize - 0.01)

    const videoNodeRef = useRef(null)
    const [refreshCanvas, set_refreshCanvas] = useState(0)
    const { showBoundary } = useErrorBoundary()
    const [selectedId, setSelectedId] = useState(null);
    const [textPosition, setTextPosition] = useState({ x: 18, y: 230 });
    const [text_x_onvideo, set_text_x_onvideo] = useState(0.03);
    const [tagPosition, setTagPosition] = useState({ x: 50, y: 20 });
    const [fontchanged, setfontchanged] = useState()
    const [video_width, setWidth] = useState();
    const [video_height, setHeight] = useState();
    const [selected, setSelected] = useState(false);
    const [selected2, setSelected2] = useState(false);
    const [isPlaying, setIsPlaying] = useState(is_play)
    const [videoSrc, setVideoSrc] = useState();
    const videoSrc_ref = useRef()
    const [thumbnail, setthumbnail] = useState()
    const [showSearchPopup, setshowSearchPopup] = useState(false)
    const [total_textHeight, setTotalTextHeight] = useState()
    const [total_text2Height, setTotalText2Height] = useState()
    const [top_offset, setTopoffset] = useState()
    const [top_offset2, setTopoffset2] = useState()
    const [v_id, set_v_id] = useState()
    const isEditing = useRef(false)
    const [text1_scaledLines, set_text1_scaledLines] = useState()
    const [text2_scaledLines, set_text2_scaledLines] = useState()

    const [text1_startPos, set_text1_startPos] = useState()
    const [text2_startPos, set_text2_startPos] = useState()
    const [canvas2videoratio, set_canvas2videoratio] = useState()
    const [widthScale, set_widthScale] = useState()
    const [heightScale, set_heightScale] = useState()
    const [textLines, setTextLines] = useState([]);
    const [text2Lines, setText2Lines] = useState([]);

    const [updateFrameData, set_updateFrameData] = useState("start")
    // const [textchanged, set_textchanged] = useState("start")
    const [frameData, set_Data] = useState()
    const [fontsize1_onVideo, set_fontsize1_onVideo] = useState(0.035)
    const [fontsize2_onVideo, set_fontsize2_onVideo] = useState(0.03)
    const [canvas_text, setCanvasText] = useState(text)
    const [canvas_text2, setCanvasText2] = useState(text2)
    const [canvas_loaded, set_canvasLoaded] = useState(false)
    const measureRef = useRef(null);
    const measure2Ref = useRef(null);
    const measure_videoRef = useRef(null);
    const measure2_videoRef = useRef(null);

    const text_padding_oncanvas = 8;
    const lineHeight_per = 0.02
    const line_padding_per = 0.02
    const blockPadding = 20;
    const blockPadding_per = 0.02;
    const start_x_per = 0.03;
    const text_padding = 0.01;
    const bottom_padding = 20;
    const text_padding_width = 7
    let canvas_width_per = 0.9
    let canvas_height_per = 1
    const maxWidth_per = 0.90
    const maxWidth_video_per = 0.73

    const text_padding_height_per = 0.01
    const text_padding_per_canvas = 0.035
    const text_padding_height_small = 4
    let bottom_padding_per = 0.04

    const [canvaswidth, set_canvaswidth] = useState(0)
    const [canvasheight, set_canvasheight] = useState(0)
    const [lineHeight, setLineHeight] = useState(0)
    const [lineHeight_withpadding, setlineHeight_withpadding] = useState(0)
    const [text_padding_canvas, set_text_padding_canvas] = useState(0)
    const [line_padding, set_line_padding] = useState(0)

    const [event_capture_width, set_event_capture_width] = useState(0)
    const [event_capture_height, set_event_capture_height] = useState(0)
    // const maxWidth = window.innerWidth * 0.73
    const [maxWidth, set_maxWidth] = useState(0)


    const fontsize1_on_canvas = useRef(0)
    const fontsize2_on_canvas = useRef(0)
    const fontsize1_on_editing = useRef(0)
    const fontsize2_on_editing = useRef(0)

    const [image_url, setImageUrl] = useState('')
    const [frame_image] = useImage(image_url);

    useEffect(() => {
        updateCanvasDimensions();
        // set_canvasheight(canvas_Height)
        // set_event_capture_height(canvas_Height)
        window.addEventListener('resize', updateCanvasDimensions);
        return () => {
            window.removeEventListener('resize', updateCanvasDimensions);
        };
    }, [window.innerWidth, window.innerHeight, parentContainer_width, canvas_Height, hide_thumbnails]);

    const getCanvasDimensions = () => {
        const parentContainer = document.getElementsByClassName('canvas-wrapper')?.[0];
        const parentWidth = parentContainer?.clientWidth;
        // const parentHeight = parentContainer?.clientHeight;
        const header_height = document.getElementsByClassName('header2-style')?.[0]?.clientHeight;
        const controls_height = document.getElementsByClassName('generate-controls')?.[0]?.clientHeight;
        const scroller_height = document.getElementsByClassName('thumb-scroll')?.[0]?.clientHeight;
        const elements_height = header_height + controls_height + scroller_height;
        const canvasHeight = window.innerHeight - elements_height;
        const canvasWidth = Math.ceil(canvasHeight * 1.778)
        // setCanvasHeight(canvasHeight);
        return { canvasWidth, canvasHeight }
    }
    const updateCanvasDimensions = () => {
        let parent_dim = getCanvasDimensions()
        set_canvaswidth(parent_dim.canvasWidth)
        let line_height = lineHeight_per * parent_dim.canvasHeight
        let text_padding = parent_dim.canvasHeight * text_padding_per_canvas
        setLineHeight(line_height)
        setlineHeight_withpadding(line_height + text_padding)
        set_line_padding(line_padding_per * parent_dim.canvasHeight)
        set_text_padding_canvas(text_padding)
        set_canvasheight(parent_dim.canvasHeight)
        set_event_capture_width(parent_dim.canvasWidth)
        set_event_capture_height(parent_dim.canvasHeight)
        set_maxWidth(parent_dim.canvasWidth * maxWidth_per)
        // set_canvaswidth(window.innerWidth * canvas_width_per)
        // set_canvasheight(window.innerHeight * canvas_height_per)
        // set_event_capture_width(window.innerWidth * canvas_width_per)
        // set_event_capture_height(window.innerHeight * canvas_height_per)
        // set_maxWidth(window.innerWidth*maxWidth_per)
    }
    useEffect(() => {
        if (canvasheight) {
            fontsize1_on_canvas.current = canvasheight * fontSize
            fontsize2_on_canvas.current = canvasheight * fontSize
            fontsize1_on_editing.current = ((canvasheight * fontSize) + 5)
            fontsize2_on_editing.current = ((canvasheight * fontSize) + 2)
        }
    }, [canvasheight])

    useEffect(() => {
        if (text)
            setCanvasText(text)
    }, [text])

    useEffect(() => {
        if (text2)
            setCanvasText(text)

    }, [text2])

    useEffect(() => {
        if (videoSrc_ref.current) {
            setIsPlaying(is_play)
            if (is_play)
                handlePlay()
            else
                handlePause()
        }
    }, [is_play])
    useEffect(() => {
        if (keyword) {
            try {
                set_isDataLoading(false)
                getVideoFrame(keyword, interest, 1, 10).then((res_frame) => {
                    let frame_results = []
                    res_frame.map((frame) => {
                        frame_results.push(frame.id)
                    })
                    let unique_frame_id = getUniqueFrame(frame_results)
                    let unique_frame = res_frame.find(x => x.id == unique_frame_id)
                    if (unique_frame) {
                        let p_frame = {
                            id: unique_frame.id,
                            text: canvas_text,
                            text2: canvas_text2,
                            keyword: keyword,
                            thumbnail: unique_frame.picture,
                            v_url: unique_frame?.link,
                            inputResolutionHeight: unique_frame?.height,
                            inputResolutionWidth: unique_frame?.width,
                            videoIsTitle: videoIsTitle,
                            // text1FontSize: fontsize1_onVideo * unique_frame?.width,
                            // text2FontSize: fontsize2_onVideo * unique_frame?.width,
                            // text1boxborderw: unique_frame?.width < 1920 ? text_padding_height_small : text_padding_height_per * unique_frame?.height,
                            // text2boxborderw: unique_frame?.width < 1920 ? text_padding_height_small : text_padding_height_per * unique_frame?.height,
                            text1FontSize: fontsize1_onVideo * desiredResolution_height,
                            text2FontSize: fontsize2_onVideo * desiredResolution_height,
                            text1boxborderw: text_padding_height_per * desiredResolution_height,
                            text2boxborderw: text_padding_height_per * desiredResolution_height,
                            text1FontFamily: textFont,
                            text2FontFamily: textFont,
                            text1Color: textColor,
                            text2Color: textColor,
                            text1BackgroundColor: textBackground,
                            text2BackgroundColor: textBackground2,
                            averageScale: 0
                        }
                        set_canvas2videoratio(0)
                        set_widthScale(0)
                        set_heightScale(0)
                        // set_v_id(p_frame?.v_id)
                        // setthumbnail(p_frame?.thumbnail)
                        setImageUrl(unique_frame.picture)
                        setVideoSrc(p_frame?.v_url)
                        videoSrc_ref.current = p_frame?.v_url
                        setHeight(p_frame?.inputResolutionHeight)
                        setWidth(p_frame?.inputResolutionWidth)
                        setFramesData(p_frame, canvas_index)
                        set_Data(p_frame)
                    }
                })
            } catch (error) {
                showBoundary(error)
            }

        }
    }, [keyword])

    const getVideoFrame = async (keyword, paper_interest, page, per_page) => {
        let response = await axios.post(env.SERVER_REQUEST_PATH + 'searchBackgrounds',
            { keyword: keyword, interest: paper_interest, page: page, per_page: per_page, doc_id: doc_id })
        return response.data.frames
    }

    useEffect(() => {
        if (canvas_text && video_width) {
            measureRef.current.attrs.fontSize = fontsize1_on_canvas.current
            measure2Ref.current.attrs.fontSize = fontsize2_on_canvas.current

            const lines = splitTextIntoLines(canvas_text, maxWidth, measureRef);
            const totalTextHeight = (lines.length * lineHeight_withpadding) + ((lines.length - 1) * line_padding);
            setTotalTextHeight(totalTextHeight)
            // Calculate top offset to position the text block at the bottom with padding
            let topOffset;
            let topOffset_w_pad;
            if (videoIsTitle)
                topOffset = (stageRef.current.height() - totalTextHeight) / 2;

            else
                topOffset = stageRef.current.height() - totalTextHeight - (bottom_padding_per * canvasheight);

            setTopoffset(topOffset)

            let lines_details = []
            lines.map((record, index) => {
                let x = videoIsTitle ? ((stageRef.current.width() - record.width) / 2) : textPosition.x
                let y = (topOffset + index * lineHeight_withpadding + (lineHeight - fontsize1_on_canvas.current) / 2 + (index * line_padding))
                let new_data = {
                    line: record.line,
                    width: record.width,
                    x: x,
                    y: y,
                    bg_y: ((topOffset + index * lineHeight_withpadding) + (index * line_padding))
                }
                lines_details.push(new_data)
            })
            set_text1_startPos({ x: lines_details[0].x, y: lines_details[0].y })
            setTextLines(lines_details);

            if (canvas_text2) {
                const lines = splitTextIntoLines(canvas_text2, maxWidth, measure2Ref);
                let topOffset2 = topOffset + totalTextHeight + blockPadding;
                setTopoffset2(topOffset2)
                let lines_details = []
                lines.map((record, index) => {
                    let x = videoIsTitle ? ((stageRef.current.width() - record.width) / 2) : textPosition.x
                    let y = (topOffset2 + index * lineHeight_withpadding + (lineHeight - fontsize2_on_canvas.current) / 2 + (index * line_padding))
                    let new_data = {
                        line: record.line,
                        width: record.width,
                        x: x,
                        y: y,
                        bg_y: ((topOffset2 + index * lineHeight_withpadding) + (index * line_padding))
                    }
                    lines_details.push(new_data)
                })
                set_text2_startPos({ x: lines_details[0].x, y: lines_details[0].y })
                setText2Lines(lines_details);
            }

            set_updateFrameData(Math.random())
        }
    }, [video_width, refreshCanvas, canvaswidth])
    useEffect(() => {
        let max_w = maxWidth_video_per * desiredResolution_width
        let start_padding = 0.035 * desiredResolution_width
        let line_height = lineHeight_per * desiredResolution_height
        let text_padding_w = 2 * text_padding_per_canvas * desiredResolution_width
        let text_padding = text_padding_per_canvas * desiredResolution_height
        let lineHeight_andpadding = line_height + (2 * text_padding)
        let betweenLine_padding = line_padding_per * desiredResolution_height
        let topOffset;
        let totalTextHeight;
        if (canvas_text) {
            measure_videoRef.current.attrs.fontSize = Math.ceil(fontsize1_onVideo * desiredResolution_height)
            measure2_videoRef.current.attrs.fontSize = Math.ceil(fontsize2_onVideo * desiredResolution_height)

            const lines = splitTextIntoLines(canvas_text, max_w, measure_videoRef);

            totalTextHeight = (lines.length * lineHeight_andpadding)
                + ((lines.length - 1) * betweenLine_padding);

            if (videoIsTitle)
                topOffset = (desiredResolution_height - totalTextHeight) / 2;

            else
                topOffset = desiredResolution_height - totalTextHeight - (bottom_padding_per * desiredResolution_height);

            let lines_details = []
            lines.map((record, index) => {
                let x = videoIsTitle ?
                    (max_w - record.width) / 2 + start_padding
                    : (start_x_per * desiredResolution_width)
                let y = (topOffset + index * lineHeight_andpadding
                    // + (line_height - (fontsize1_onVideo * desiredResolution_height)) / 2
                    + (index * betweenLine_padding))
                let new_data = {
                    line: record.line,
                    scaled_x: x,
                    scaled_y: y,
                    scaled_width: record.width
                }
                lines_details.push(new_data)
            })
            set_text1_scaledLines(lines_details);

            if (canvas_text2) {
                const lines = splitTextIntoLines(canvas_text2, max_w, measure2_videoRef);
                let topOffset2 = topOffset + totalTextHeight
                    + (blockPadding_per * desiredResolution_height);

                let lines_details = []
                lines.map((record, index) => {
                    let x = videoIsTitle ?
                        (max_w - record.width) / 2 + start_padding
                        : (start_x_per * desiredResolution_width)
                    let y = (topOffset2 + index * lineHeight_andpadding
                        // + (line_height - (fontsize2_onVideo * desiredResolution_height)) / 2
                        + (index * betweenLine_padding))
                    let new_data = {
                        line: record.line,
                        scaled_width: record.width,
                        scaled_x: x,
                        scaled_y: y
                    }
                    lines_details.push(new_data)
                })
                set_text2_scaledLines(lines_details);
            }

            set_updateFrameData(Math.random())
        }
    }, [canvas_text, canvas_text2])
    // useEffect(() => {
    //     if (top_offset && canvas_text2) {
    //         const lines = splitTextIntoLines(canvas_text2, maxWidth, measure2Ref);
    //         let topOffset = top_offset + total_textHeight + blockPadding;
    //         setTopoffset2(topOffset)
    //         let lines_details = []
    //         lines.map((record, index) => {
    //             let x = videoIsTitle ? ((stageRef.current.width() - record.width) / 2) : textPosition.x
    //             let y = (topOffset + index * lineHeight_withpadding + (lineHeight - fontsize2_on_canvas) / 2 + (index * line_padding))
    //             let new_data = {
    //                 line: record.line,
    //                 width: record.width,
    //                 x: x,
    //                 y: y,
    //                 bg_y: ((topOffset + index * lineHeight_withpadding) + (index * line_padding))
    //             }
    //             lines_details.push(new_data)
    //         })
    //         set_text2_startPos({ x: lines_details[0].x, y: lines_details[0].y })
    //         setText2Lines(lines_details);
    //         set_updateFrameData(Math.random())
    //     }
    // }, [canvas_text2, top_offset, canvaswidth])

    const splitTextIntoLines = (fullText, maxWidth, mRef) => {
        const words = fullText.split(' ');
        const lines = [];
        let currentLine = words[0];

        const measureText = (fullText, text_refrence) => {
            if (text_refrence.current) {
                text_refrence.current.text(fullText);
                return text_refrence.current.width();
            }
            return 0;
        };

        for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const width = measureText(currentLine + ' ' + word, mRef);
            if (width < maxWidth) {
                currentLine += ' ' + word;
            } else {
                lines.push({ width: measureText(currentLine, mRef) + text_padding_canvas, line: currentLine });
                currentLine = word;
            }
        }
        lines.push({ width: measureText(currentLine, mRef) + text_padding_canvas, line: currentLine });
        return lines;
    };

    const calculateScaledLines = (scale_ratio, width_ratio, height_ratio, videoWidth, videoHeight) => {
        let scaled_data1 = []
        const totalHeight = total_textHeight * height_ratio
        let topOffset;
        if (videoIsTitle)
            topOffset = (videoHeight - totalHeight) / 2;

        else
            topOffset = videoHeight - totalHeight - (bottom_padding_per * videoHeight);

        textLines.map((line, index) => {
            let x;
            // let y = (topOffset + index * (2 * ((desiredResolution_height * text_padding_per_canvas))
            //     + (lineHeight_per * desiredResolution_height))
            //     + (index * (line_padding_per * desiredResolution_height)))
            let y = (line.y * height_ratio) + (index * (line_padding_per * desiredResolution_height))

            let scaled_width = line.width * width_ratio

            if (!videoIsTitle)
                x = text_x_onvideo * videoWidth
            else
                x = (videoWidth - ((line.width - (2 * text_padding_per_canvas * canvaswidth)) * width_ratio)) / 2

            // x = ((videoWidth - ((line.width - (2 * text_padding_canvas)) * width_ratio)
            //     - (videoWidth * text_padding)) / 2)

            let old_y = line.y * height_ratio
            let scaled_line = {
                line: line.line,
                scaled_x: x,
                scaled_y: y,
                scaled_width: scaled_width
            }
            scaled_data1.push(scaled_line)
        })
        set_text1_scaledLines(scaled_data1)

        let scaled_data2 = []
        if (canvas_text2) {
            let topOffset2 = topOffset + totalHeight + (blockPadding * height_ratio);

            text2Lines.map((line, index) => {
                let y = (topOffset2 + index * (lineHeight_withpadding * height_ratio) +
                    ((lineHeight * height_ratio) - (fontsize2_onVideo * videoWidth)) / 2 +
                    (index * (line_padding * height_ratio)))

                let scaled_width = line.width * width_ratio
                // let x = (videoWidth - ((line.width - (2 * text_padding_canvas)) * width_ratio)
                // - (videoWidth * text_padding)) / 2

                let x = (videoWidth - ((line.width * width_ratio) - (2 * (text_padding_per_canvas * desiredResolution_width)))) / 2

                let old_y2 = height_ratio * line.y
                let scaled_line = {
                    line: line.line,
                    scaled_x: x,
                    scaled_y: y,
                    scaled_width: scaled_width
                }
                scaled_data2.push(scaled_line)
            })
            set_text2_scaledLines(scaled_data2)
        }
        return { scaled_data1, scaled_data2 }
    }

    useEffect(() => {
        if (frameData && updateFrameData != "start" && canvaswidth > 0) {
            // const scaling = calculateAverageScale(canvaswidth, canvasheight,
            //     // frameData?.inputResolutionWidth,
            //     desiredResolution_width,
            //     // frameData?.inputResolutionHeight
            //     desiredResolution_height)
            let scaled_data = frameData
            // scaled_data.averageScale = scaling.averageScale
            // set_Data(scaled_data)
            // set_canvas2videoratio(scaling.averageScale)
            // set_widthScale(scaling.widthScale)
            // set_heightScale(scaling.heightScale)
            // set_updateFrameData(Math.random())
            // let scaled_res = calculateScaledLines(scaling.averageScale, scaling.widthScale,
            //     scaling.heightScale,
            //     //  video_width, video_height
            //     desiredResolution_width, desiredResolution_height)
            let scaledFrames = {
                ...scaled_data,
                // scaled_lines1: scaled_res.scaled_data1,
                // scaled_lines2: scaled_res.scaled_data2
                scaled_lines1: text1_scaledLines,
                scaled_lines2: text2_scaledLines
            }
            set_Data(scaledFrames)

            setFramesData(scaledFrames, canvas_index)
        }
    }, [updateFrameData])
    useEffect(() => {
        if (textNodeRef.current) {
            // Calculate the size of the tag based on the text
            const fontSizechanged = textNodeRef.current.attrs.fontSize
            setFontSize(fontSizechanged)// + padding * 2);
        }
    }, [fontchanged]);

    useEffect(() => {
        if (text2NodeRef.current) {
            // Calculate the size of the tag based on the text
            const fontSizechanged = text2NodeRef.current.attrs.fontSize
            setFontSize(fontSizechanged)// + padding * 2);
        }
    }, [fontchanged]);
    function calculateAverageScale(originalWidth, originalHeight, newWidth, newHeight) {
        const widthScale = newWidth / (originalWidth);
        const heightScale = newHeight / (originalHeight);
        const averageScale = (widthScale + heightScale) / 2;
        return { averageScale, widthScale, heightScale }
    }

    useEffect(() => {
        videoRef.current.src = videoSrc_ref.current
    }, [videoSrc_ref.current])
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.load();

            const animate = () => {
                if (video.paused || video.ended) {
                    return;
                }
                videoNodeRef.current?.getLayer().batchDraw();
                requestAnimationFrame(animate);
            };

            video.addEventListener("loadeddata", () => {
                video.play()
                const layer = videoNodeRef.current.getLayer();

                const anim = new Konva.Animation(() => { }, layer);
                anim.start();
                // videoNodeRef.current?.getLayer().batchDraw(); // Draw the first frame
                // animate();
                video.pause();
                set_isDataLoading(true)
            });
            video.addEventListener("play", () => {
                setIsPlaying(true)
            })
            video.addEventListener("pause", () => {
                setIsPlaying(false)
            })
        }
    }, [videoNodeRef.current]);

    const updateCanvasText = () => {
        isEditing.current = false
        // updateFrameText(canvas_text, canvas_text2, canvas_index)
        set_refreshCanvas(Math.random())
    }
    const setEditableText = (value, text_type) => {
        if (text_type == "t1")
            setCanvasText(value)
        else if (text_type == "t2")
            setCanvasText2(value)
    }
    const handleVideoSelected = (src) => {
        setVideoSrc(src);
    };
    const add_new_background = (selected_bg) => {
        if (selected_bg?.[0]) {
            setVideoSrc(selected_bg[0].fig_path)
            videoSrc_ref.current = selected_bg[0].fig_path
            videoRef.current.src = selected_bg[0].fig_path

            // const scaling = calculateAverageScale(canvaswidth, canvasheight,
            //     selected_bg[0].inputResolutionWidth, selected_bg[0].inputResolutionHeight)

            // set_canvas2videoratio(scaling.averageScale)
            // set_widthScale(scaling.widthScale)
            // set_heightScale(scaling.heightScale)
            setWidth(selected_bg[0].inputResolutionWidth)
            setHeight(selected_bg[0].inputResolutionHeight)

            set_Data({
                id: selected_bg[0].fig_id,
                inputResolutionHeight: selected_bg[0].inputResolutionHeight,
                inputResolutionWidth: selected_bg[0].inputResolutionWidth,
                thumbnail: selected_bg[0].thumbnail,
                v_url: selected_bg[0].fig_path,
                videoIsTitle: videoIsTitle,
                text: canvas_text,
                text2: canvas_text2,
                keyword: keyword,
                // text1FontSize: fontsize1_onVideo * selected_bg[0].inputResolutionWidth,
                // text2FontSize: fontsize2_onVideo * selected_bg[0].inputResolutionWidth,
                // text1boxborderw: selected_bg[0].inputResolutionWidth < 1920 ? text_padding_height_small : text_padding_height_per * selected_bg[0].inputResolutionHeight,
                // text2boxborderw: selected_bg[0].inputResolutionWidth < 1920 ? text_padding_height_small : text_padding_height_per * selected_bg[0].inputResolutionHeight,
                text1FontSize: fontsize1_onVideo * desiredResolution_height,
                text2FontSize: fontsize2_onVideo * desiredResolution_height,
                text1boxborderw: text_padding_height_per * desiredResolution_height,
                text2boxborderw: text_padding_height_per * desiredResolution_height,

                text1FontFamily: textFont,
                text2FontFamily: textFont,
                text1Color: textColor,
                text2Color: textColor,
                text1BackgroundColor: textBackground,
                text2BackgroundColor: textBackground2,
                averageScale: 0
            })
            set_updateFrameData(Math.random())
        }
        // set_v_id(selected_bg[0].fig_id)
        // setthumbnail(selected_bg[0].thumbnail)
    }
    const handlePlay = () => {
        set_canvas_video_loaded(true)
        let video = videoRef.current;
        video.play();
        const layer = videoNodeRef.current.getLayer();

        const anim = new Konva.Animation(() => { }, layer);
        anim.start();
    };

    const handlePause = () => {
        videoRef.current.pause();
    };
    const handleTextDoubleClick = () => {
        setSelectedId("editableText");
        textNodeRef.current.text(canvas_text);
        textNodeRef.current.getStage().batchDraw();
    };
    const handleStageMouseDown = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelectedId(null);
        }
    };

    const handleTextPositionChange = (e) => {
        setTextPosition({
            x: e.target.x(),
            y: e.target.y(),
        });
    };
    const handleTextTransform = (e) => {
        const node = textNodeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        node.scaleX(1);
        node.scaleY(1);
        setTextPosition({
            x: node.x(),
            y: node.y(),
        });
        node.scaleX(scaleX);
        node.scaleY(scaleY);

    };
    const handleTagPositionChange = (e) => {
        setTagPosition({
            x: e.target.x(),
            y: e.target.y(),
        });
    };
    const handleTagTransform = (e) => {
        const node = tagRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        node.scaleX(1);
        node.scaleY(1);
        setTagPosition({
            x: node.x(),
            y: node.y(),
        });
        node.scaleX(scaleX);
        node.scaleY(scaleY);
    };
    const handleTransformerTransform = (e) => {
        setfontchanged(Math.random())
    };
    return (
        <div className="position-relative ">
            {/* {!is_dataLoading &&
                <div className='d-flex w-100 justify-content-center align-items-center'>
                    <PropagateLoader color="#B9AFF7" />
                </div>
            } */}
            <div className="d-flex justify-content-center">
                <div>
                    {/* <div > */}
                    <div className="m-2 p-1 d-flex justify-content-center align-items-center search_bk_icon" onClick={() => {
                        pauseAllFrames()
                        setshowSearchPopup(true)
                    }}
                        role='button'><IoIosSearch size={25} color={`${'5644de'}`} /></div>
                </div>
                {/* </div> */}
                <div className="d-flex justify-content-center">
                    <Stage
                        className="stage_comp"
                        ref={stageRef}
                        width={canvaswidth}
                        height={canvasheight}
                        onMouseDown={handleStageMouseDown}
                        onClick={(e) => {
                            if (e.currentTarget._id === e.target._id) {
                                setSelected(false);
                                setSelected2(false)
                            }
                        }}
                        onDblClick={() => {
                            isEditing.current = true
                            set_refreshCanvas(Math.random())

                        }}
                    >
                        <Layer>
                            {videoSrc_ref.current && (<Image
                                ref={videoNodeRef}
                                image={videoRef.current}
                                width={canvaswidth}
                                height={canvasheight}
                                opacity={videoOpacity}
                                onDblClick={() => {
                                    isEditing.current = true
                                    set_refreshCanvas(Math.random())
                                }}

                            />)}

                            {/* {!canvas_video_loaded && videoSrc_ref.current && (<Image
                            // ref={videoNodeRef}
                            image={frame_image}
                            width={canvaswidth}
                            height={canvasheight}
                            opacity={videoOpacity}
                            onDblClick={() => { isEditing.current = true }}
                        />)} */}

                            {videoSrc_ref.current && (<Rect
                                width={event_capture_width}
                                height={event_capture_height}
                                fill="rgba(0,0,0,0)" // Transparent fill to capture events
                                // onClick={(e) => {
                                //     if (isPlaying)
                                //         handlePause()
                                //     else
                                //         handlePlay()
                                //     // setTextPosition({ x: e.evt.offsetX, y: e.evt.offsetY });

                                // }}
                                onDblClick={() => {
                                    isEditing.current = true
                                    set_refreshCanvas(Math.random())

                                }}
                            />
                            )}
                            <Group onDblClick={() => {
                                isEditing.current = true
                                set_refreshCanvas(Math.random())

                            }}>
                                {isEditing.current &&
                                    <TextEditor
                                        ref={textNodeRef}
                                        id="editableText"
                                        text={textLines?.[0]?.line}
                                        fullText={canvas_text}
                                        editor_x={text1_startPos?.x}
                                        editor_y={text1_startPos?.y - (text_padding_per_canvas * canvasheight)}
                                        editor_height={total_textHeight + (text_padding_per_canvas * canvasheight)}
                                        x={textLines?.[0]?.x}
                                        y={textLines?.[0]?.y}
                                        is_Editing={isEditing.current}
                                        onTextChange={(value) => setEditableText(value, "t1")}
                                        width={textLines?.[0]?.width}
                                        background_y={textLines?.[0]?.bg_y}
                                        background_height={lineHeight_withpadding}
                                        background_width={textLines?.[0]?.width + text_padding_width}
                                        index={0}
                                        textBackground={textBackground}
                                        // height={height}
                                        height={lineHeight}
                                        borderColor={textBorderColor}
                                        borderWidth={textBorderWidth}
                                        fontSize={fontsize1_on_canvas.current}
                                        editing_fontsize={fontsize1_on_editing.current}
                                        textFont={textFont}
                                        textColor={textColor}
                                        borderRadius={borderRadius}
                                        backgroundOpacity={backgroundOpacity}
                                        selected={selected}
                                        updateWholeText={updateCanvasText}
                                        rec_ref={last_line_text1_ref}

                                        onClick={() => {
                                            setSelected(!selected);
                                        }}
                                        onTextClick={(newSelected) => {
                                            setSelected(newSelected);
                                        }}
                                    />
                                }
                                {!isEditing.current && textLines.map((record, index) => (
                                    <TextEditor
                                        ref={textNodeRef}
                                        id="editableText"
                                        text={record.line}
                                        fullText={canvas_text}
                                        editor_x={text1_startPos.x}
                                        editor_y={text1_startPos.y}
                                        editor_height={total_textHeight}
                                        x={record?.x}
                                        y={record?.y}
                                        is_Editing={isEditing.current}
                                        text_padding={(text_padding_per_canvas * canvasheight) / 2}
                                        onTextChange={(value) => setEditableText(value, "t1")}
                                        width={record?.width}
                                        background_y={record?.bg_y}
                                        background_height={lineHeight_withpadding}
                                        background_width={record.width + text_padding_width}
                                        index={index}
                                        textBackground={textBackground}
                                        // height={height}
                                        height={lineHeight}
                                        borderColor={textBorderColor}
                                        borderWidth={textBorderWidth}
                                        fontSize={fontsize1_on_canvas.current}
                                        editing_fontsize={fontsize1_on_editing.current}
                                        textFont={textFont}
                                        textColor={textColor}
                                        borderRadius={borderRadius}
                                        backgroundOpacity={backgroundOpacity}
                                        selected={selected}
                                        updateWholeText={updateCanvasText}
                                        openEditMode={() => {
                                            isEditing.current = true
                                            set_refreshCanvas(Math.random())
                                        }}
                                        rec_ref={index == textLines.length - 1 ? last_line_text1_ref : null}

                                        onClick={() => {
                                            setSelected(!selected);
                                        }}
                                        onTextClick={(newSelected) => {
                                            setSelected(newSelected);
                                        }}
                                    />
                                ))}
                                {isEditing.current && canvas_text2 &&
                                    <TextEditor
                                        ref={text2NodeRef}
                                        id="editableText2"
                                        text={text2Lines?.[0]?.line}
                                        fullText={canvas_text2}
                                        editor_x={text2_startPos?.x}
                                        editor_y={text2_startPos?.y - (text_padding_per_canvas * canvasheight)}
                                        editor_height={total_text2Height + (text_padding_per_canvas * canvasheight)}
                                        x={text2Lines?.[0]?.x}
                                        y={text2Lines?.[0]?.y}
                                        is_Editing={isEditing.current}
                                        onTextChange={(value) => setEditableText(value, "t2")}
                                        width={text2Lines?.[0]?.width}
                                        background_y={text2Lines?.[0]?.bg_y}
                                        background_height={lineHeight_withpadding}
                                        background_width={text2Lines?.[0]?.width + text_padding_width}
                                        index={0}
                                        textBackground={textBackground2}

                                        height={lineHeight}
                                        borderColor={textBorderColor}
                                        borderWidth={textBorderWidth}
                                        fontSize={fontsize2_on_canvas.current}
                                        editing_fontsize={fontsize2_on_editing.current}
                                        textFont={textFont}
                                        textColor={textColor}
                                        borderRadius={borderRadius}
                                        backgroundOpacity={backgroundOpacity}
                                        selected={selected}
                                        updateWholeText={updateCanvasText}
                                        rec_ref={last_line_text2_ref}

                                        onClick={() => {
                                            setSelected2(!selected2);
                                        }}
                                        onTextClick={(newSelected) => {
                                            setSelected2(newSelected);
                                        }}
                                    />}
                                {!isEditing.current && text2Lines.map((record, index) => (

                                    <TextEditor
                                        ref={text2NodeRef}
                                        id="editableText2"
                                        text={record.line}
                                        fullText={canvas_text2}
                                        editor_x={text2_startPos.x}
                                        editor_y={text2_startPos.y}
                                        editor_height={total_text2Height}
                                        x={record?.x}
                                        y={record?.y}
                                        is_Editing={isEditing.current}
                                        onTextChange={(value) => setEditableText(value, "t2")}
                                        width={record?.width}
                                        background_y={record?.bg_y}
                                        background_height={lineHeight_withpadding}
                                        background_width={record?.width + text_padding_width}
                                        index={index}
                                        textBackground={textBackground2}
                                        text_padding={(text_padding_per_canvas * canvasheight) / 2}
                                        height={lineHeight}
                                        borderColor={textBorderColor}
                                        borderWidth={textBorderWidth}
                                        fontSize={fontsize2_on_canvas.current}
                                        editing_fontsize={fontsize2_on_editing.current}
                                        textFont={textFont}
                                        textColor={textColor}
                                        borderRadius={borderRadius}
                                        backgroundOpacity={backgroundOpacity}
                                        selected={selected}
                                        updateWholeText={updateCanvasText}
                                        rec_ref={index == text2Lines.length - 1 ? last_line_text2_ref : null}

                                        onClick={() => {
                                            setSelected2(!selected2);
                                        }}
                                        onTextClick={(newSelected) => {
                                            setSelected2(newSelected);
                                        }}
                                    />
                                ))}
                            </Group>

                            <Text // Hidden text element for measurement
                                ref={measureRef}
                                visible={false}
                                fontSize={fontsize1_on_canvas.current}
                                fontFamily={textFont}
                            />
                            <Text // Hidden text element for measurement
                                ref={measure2Ref}
                                visible={false}
                                fontSize={fontsize2_on_canvas.current}
                                fontFamily={textFont}
                            />
                            <Text // Hidden text element for measurement
                                ref={measure_videoRef}
                                visible={false}
                                fontSize={Math.ceil(fontsize2_onVideo * desiredResolution_height)}
                                fontFamily={textFont}
                            />
                            <Text // Hidden text element for measurement
                                ref={measure2_videoRef}
                                visible={false}
                                fontSize={Math.ceil(fontsize2_onVideo * desiredResolution_height)}
                                fontFamily={textFont}
                            />
                        </Layer>
                    </Stage>

                </div>

            </div>
            <video ref={videoRef} muted hidden />
            <SearchBackground interest={interest} closeModal={() => {
                setshowSearchPopup(false)
                updateCanvasDimensions()
            }} showModal={showSearchPopup} submitSelected={add_new_background} />

        </div>
    );
};

export default Canvas;
