import React from 'react'
import GenerateTerms from '../../PDFGeneration/Components/GenerateTerms'
import { useStateValue } from "../../index";
import Header from '../Components/Header';
import FingerLeftMenu from '../Components/Main/FingerLeftMenu';
import Footer from '../../SharedComponents/js/Footer';
import { useErrorBoundary } from "react-error-boundary";
import MiniFooter from '../../SharedComponents/js/Mini-Footer';


const WebsiteTerms = () => {
    const [userDetails, dispatch] = useStateValue();
    const { showBoundary } = useErrorBoundary();

    return (
        <div className='d-flex flex-column ProfileContainer' >
            <div className='headercontainer'>
                <Header noUser={true} userdata={userDetails} catchErrors={(err) => {
                    showBoundary(err)
                }} />
            </div>

            <div className='m-5 p-5 websiteTerms-content'>
                <GenerateTerms />
            </div>
            <FingerLeftMenu />
            <MiniFooter />

        </div>
    )
}

export default WebsiteTerms
