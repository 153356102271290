import React from 'react'
import '../css/Header.css'
import { useNavigate, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
const env = require('../../config.env')

const Header = () => {
    const navigate = useNavigate()
    const navigateHome = () => {
        const token = localStorage.getItem("user-token")
        if (token)
            navigate('/home')
        else
            navigate('/login')
    }
    return (

        <header className="px-3 py-2 border border-1 header-style">
            <div className="header w-100">
                <div className="p-0 container-fluid d-flex flex-wrap 
                align-items-center justify-content-center justify-content-lg-start">
                    <a className="text-center text-md-start navbar-brand" href="/">
                        <img src={'/assets/pageLogo.png'} alt="logo" className='h-logo-size' />
                    </a>

                    <ul className="ms-lg-auto align-items-center nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 h-list-style">
                        {/* <li><NavLink href="#" className="nav-item px-2 navbar-dark" to="/">Academics</NavLink></li> */}
                        {/* <li><NavLink href="#" className="nav-item px-2 navbar-dark" to="/">Business</NavLink></li> */}
                        {/* <li className="my-0 px-2">
                            <Dropdown >
                                <Dropdown.Toggle variant='Default'>Resources</Dropdown.Toggle>
                                <Dropdown.Menu className='land-drop'>
                                    <Dropdown.Item eventKey={3.1}>Features</Dropdown.Item>
                                    <Dropdown.Item eventKey={3.2}>Vodcast</Dropdown.Item>
                                    <Dropdown.Item eventKey={3.3}>News</Dropdown.Item>
                                    <Dropdown.Item eventKey={3.3}>Vision</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li> */}
                    </ul>
                    <div className='d-flex justify-content-end col-md-auto ms-md-5 text-end'>
                        <div className='row m-0 p-0'>
                            <div className='col-md-auto col-7'>
                                <button onClick={() => {
                                    navigate('/sign-up')
                                }} className='btn-sign'>Sign Up</button>
                            </div>
                            <div className='col-md-auto col-5'>
                                <button onClick={() => {
                                    navigate('/login')
                                }} className='mt-md-2 mt-lg-0 mt-md-0 ms-md-0 ms-md-5 ms-lg-0 btn-login'>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}

export default Header
