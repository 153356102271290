import React, { useState, useEffect, useRef } from 'react'
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BiCheck, BiSearch } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai'
import Alert from 'react-bootstrap/Alert'
import axios from "axios";
// import { debounce } from "../uils/debounce";
const env = require('../../config.env')

const ResearchInterest = (props) => {
    const searchRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [friends, setFriends] = useState([])
    const [selected_friends, setselected_friends] = useState([])
    const [isHide, setIsHide] = useState(true);
    const div_ref = useRef()
    useEffect(() => {
        if (selected_friends) {
            props.selected(interests)
        }
    }, [selected_friends]);

    const handleChange = (event) => {
        let value = event.target.value;
        if (!value) {
            setFilteredInterests(interests)
        }
        setSearchValue(value)
    }

    const removeTag = (tag_index) => {
        const changed_friends = []
        selected_friends.map((f, index) => {
            if (index != tag_index)
                changed_friends.push(f)
        })
        setselected_friends(changed_friends)
    }

    const selectFriend = (friend) => {
        let found = selected_friends.find(x => x.id == friend.id)
        if (!found) {
            setSearchValue("")
            setIsHide(true)
            setselected_friends([...selected_friends, friend])
        }
    }

    const debounce = (func, delay = 3000) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    useEffect(() => {
        const debouncedSave = debounce(() => {
            setFilteredInterests(interests.filter(x => x.title.includes(searchValue)))
        }, 500);
        debouncedSave(searchValue);
    }, [searchValue]);

    const [interests, getinterests] = useState([])
    const [filteredInterests, setFilteredInterests] = useState([])

    useEffect(() => {
        axios.get(env.SERVER_REQUEST_PATH + 'interests')
            .then((response) => {
                let data = response.data.data
                getinterests(data)
                setFilteredInterests(data)
            }).catch(err => {
                console.error(`Error: ${err}`)
            })
    }, [])
    useEffect(() => {
        document.addEventListener('mousedown', handleHide);
        return () => {
            document.removeEventListener('mousedown', handleHide);
        };
    }, [])
    const handleHide = (e) => {
        if (div_ref.current && !div_ref.current.contains(e.target) && !searchRef.current.contains(e.target)) {
            setIsHide(true);
        }
    }
    return (
        <div className='w-100 mb-3 position-relative'>
            <label className="form-label input-label">Research Interest</label>
            <div className='form-control input-box p-0'>
                <InputGroup>
                    <div className='d-flex flex-wrap gap-2 border-0 p-2 '>
                        {
                            selected_friends.length >= 1 &&
                            selected_friends.map((f, index) => {
                                return <Badge key={"f-" + index} className='ms-1 custom-badge-primary' text="white" >
                                    {f.title}
                                    <AiOutlineClose className='ms-2' onClick={() => removeTag(index)} />
                                </Badge>
                            })}
                        <Form.Control
                            className='form-control input-box border-0 mb-0 p-0 research-interest-input'
                            aria-label="Research interest"
                            aria-describedby="basic-addon2"
                            ref={searchRef}
                            onChange={handleChange}
                            value={searchValue}
                            // onBlur={() => setIsHide(true)}
                            onFocus={() => setIsHide(false)}
                        />
                    </div>
                </InputGroup>
            </div>
            <div ref={div_ref} className="interests-search" hidden={isHide ? 'hidden' : ''} >
                {filteredInterests.map((interest) => {
                    let found = selected_friends.find(x => x.id == interest.id)
                    return (
                        <div onClick={() => {
                            selectFriend(interest)
                        }}>
                            <div className="user-result" key={interest.id}>
                                <p className={`${found ? 'selected-interest' : ''} mb-0`}>
                                    {interest.title} {found && <BiCheck className='ms-1' />}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ResearchInterest
