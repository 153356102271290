import { useState, useCallback } from "react";
import { Stack, Button, Form, Alert } from "react-bootstrap";
import axios from 'axios'
import '../../../css/PasswordForm.css'
const env = require('../../../../config.env')

const PasswordForm = (props) => {
    const [oldPass, setoldPass] = useState('')
    const [newPass, setnewPass] = useState('')
    const [cancelFlag, setcancelFlag] = useState(false)
    const [alert_msg, setalert_msg] = useState('')
    const [error, seterror] = useState(false)
    const [alert_show, setalert_show] = useState("hidden")

    const showAlert = (msg) => {
        setalert_msg(msg)
        setalert_show("")
        setTimeout(() => {
            hideAlert();
        }, 3000);
    }
    const hideAlert = () => {
        setalert_msg('')
        setalert_show("hidden")
    }
    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    const onInputChange = (event) => {
        let changedInput = event.target.name
        let changedValue = event.target.value

        switch (changedInput) {
            case 'oldPass':
                setoldPass(changedValue)
                return
            case 'newPass':
                setnewPass(changedValue)
                return
        }

    }
    let optimisedHandleChange = useCallback(
        debounce(onInputChange, 500)
        ,
        []
    );

    const submitPassword = (e) => {

        e.preventDefault()
        if (!cancelFlag) {
            let pass = {
                oldPass: oldPass, newPass: newPass
            }
            axios.post(env.SERVER_REQUEST_PATH + 'changePassword', pass)
                .then((response) => {
                    let status = response.status
                    if (status == 200) {
                        if (response.data.iscorrect)
                            seterror(false)

                        else
                            seterror(true)
                        showAlert(response.data.msg)
                        props.onHide()
                    }

                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
        }
        e.target.reset()
    }
    return (
        <Form autocomplete="off" onSubmit={submitPassword} >
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Enter old Password</Form.Label>
                <Form.Control required onChange={optimisedHandleChange} name="oldPass" type="password" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicNewPassword">
                <Form.Label>Enter new Password</Form.Label>
                <Form.Control required onChange={optimisedHandleChange} name="newPass" type="password" />
            </Form.Group>
            <Stack gap={2} direction="horizontal" className="d-flex justify-content-end">
                <Button className="btn-font-size" variant="secondary" onClick={(e) => props.onHide()} type="submit">Cancel</Button>
                <Button className="style-button btn-font-size" variant="primary" onClick={(e) => setcancelFlag(false)} type="submit">Save Changes</Button>
            </Stack>
            <Alert key={error ? 'danger' : 'success'} variant={error ? 'danger' : 'success'}
                className='mt-2 alert-style'
                hidden={alert_show}>
                {alert_msg}
            </Alert>
        </Form>
    )
}

export default PasswordForm;