import React, { useEffect, useRef, useState } from "react";

import '../css/VideoViewer.css'
import { PropagateLoader, PulseLoader } from 'react-spinners';
import { Player } from "react-tuby";
import axios from "axios";
import "react-tuby/css/main.css";
const env = require('../../config.env')

const VideoViewer = (props) => {

  const videoSrc_ref = useRef()
  const [video_loaded, setVideoLoaded] = useState()

  useEffect(() => {
    const fetchVideo = async () => {
      if (props.link) {
        try {
          const response = await axios.get(env.SERVER_REQUEST_PATH + props.link, { responseType: 'blob' });
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'video/mp4' }));
          videoSrc_ref.current = url;
          setVideoLoaded(Math.random())
        } catch (error) {
          console.error('Error fetching video:', error);
        }
      }
    }
    if (props.type != "normal")
      fetchVideo();
    return () => {
      if (videoSrc_ref.current) {
        window.URL.revokeObjectURL(videoSrc_ref.current);
      }
    };

  }, [props.link])

  const handlePlay = (e) => {
    if (!videoSrc_ref.current)
      e.preventDefault();
  }
  // if ()
  //   return (

  //   )
  return (
    <>
      {props.type != "normal" && !videoSrc_ref.current &&
        <div className="viewer w-100 d-flex justify-content-center align-items-center loadind-viewer-container">
          <PropagateLoader color="#B9AFF7" /></div>}
      <div className={`${props.type == "normal" ? 'border-0' : ''} viewer`}>
        {props.type != "normal" && videoSrc_ref.current &&
          < Player
            primaryColor="#5644de"
            keyboardShortcut={{
              pause: false,
              forward: true,
              rewind: true,
              fullScreen: true,
              mute: true,
              subtitle: true,
            }}
            onPlay={handlePlay}
            src={videoSrc_ref.current ? videoSrc_ref.current : ''}
            // src={env.SERVER_REQUEST_PATH + props.link}
            // src={props.type == "publish" ? `data:video/mp4;base64,${props.link}` : videoSrc ? videoSrc : ''}
            subtitles={props.subtitle ? [
              {
                lang: "en",
                language: "English",
                url: `data:text/vtt;base64,${props.subtitle}`
              }] : ''}
            poster={env.SERVER_REQUEST_PATH + props.thumb}
          // poster={props.type == "publish" ? `data:image/*;base64,${props.thumb}` : posterSrc ? `data:image/*;base64,${posterSrc}` : ''}
          />

        }
        {
          props.type == "normal" && <Player
            primaryColor="#5644de"
            keyboardShortcut={{
              pause: false,
              forward: true,
              rewind: true,
              fullScreen: true,
              mute: true,
              subtitle: true
            }}
            autoplay
            poster={props.thumb ? `${props.thumb}` : ''}
            src={props.link ? `${props.link}` : ''}>
          </Player>
        }
      </div >
    </>
  );
};
export default VideoViewer;