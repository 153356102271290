import React, { useState } from 'react'
import Card from 'react-bootstrap/Card';
import Header from '../../SharedComponents/js/Header'
import '../css/Landing.css'
import Footer from '../../SharedComponents/js/Footer'
import FeedBackScroller from '../Components/FeedBackScroller';
import { useNavigate, NavLink } from 'react-router-dom';
import VideoViewer from '../../SharedComponents/js/VideoViewer';
import WaitList from '../Components/WaitList';

const Landing = () => {
    const navigate = useNavigate()
    const [showWaitList, setShow] = useState(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <Header />
            <div className='landing-container'>
                <div className='first-sec-container'>
                    {/* <div className='first-sec'> */}
                    <span className='mt-5 pt-5 slogan'>Built for researchers,
                        by researchers.</span>
                    <p className='mt-5 mb-lg-5 text-style'>
                        {/* Be a part of our academic community operated by artificial intelligence.
                         Together, let us distribute our research publications in multimedia format 
                         with millions of researchers from all over the world.
                          */}

                        Be part of our academic community operated by
                        artificial intelligence. Together, let us distribute our research publications
                        in multimedia format with millions of researchers from all over the world.
                        We ensure that your research discoveries get the attention they deserve.
                    </p>
                    <button
                        onClick={() => {
                            handleShow()
                            //     navigate('/SignUp')
                        }}
                        className='mt-2 btn-free'>Try ResearchTube.Ai</button>
                    {/*{/* <img className='mockup-img mt-5 col-12 col-md-9' src='/assets/mockPhoto.png' /> */}
                    {/* <div className='mockup-img mt-5 col-12 col-md-8'>
                        <VideoViewer type='normal' thumb={'/assets/thumb.png'} link={'/assets/Website_video.mp4'} />
                    </div> */}
                    {/* {/* </div> */}


                </div>
                {/* <img className='d-none d-md-flex mokup_sec mt-5 col-12 col-md-12' src='/assets/subtract.png' /> */}
                {/* edit mb-lg-4  */}
                <div className='second-sec-container'>
                    <div className='col-8 col-md-5 col-lg-4'>
                        <img className='second-sec-img' src="/assets/transform-pdf.png" /></div>
                    <div className='second-sec-text col-8 mt-md-0 mt-5 col-lg-3 col-md-4'>
                        <span className='mt-5 second-sec-t'>
                            Artificial intelligence constructed to enhance the research process.
                        </span>
                        <p className='mt-3 second-sec-p'>
                            {/* Transform PDF publications into videos effortlessly, 
                            with no need of video making experience. */}
                            Summarize research articles to emphasize the crucial information.
                        </p>
                        <button
                            onClick={() => {
                                handleShow()
                                //     navigate('/SignUp')
                            }}
                            className='btn-transform'>Transform Your Publications</button>
                    </div>
                </div>

                {/* <div className='mt-5 mt-md-0 mt-lg-0 third-sec-container'>
                    <div className='pb-5 pt-5 third-sec'>
                        <p className='my-5 third-sec-t'>Simple, yet Powerful Features</p>
                        <div className='cards-container'>
                            <div className='third-sec-cards'>
                                <Card className='card-style'>
                                    <Card.Body>
                                        <Card.Title className='px-0 card-title-style'>
                                            <p className='card-tag'>Text</p>
                                            <p className='card-heading'>A.I. text summarization</p>
                                        </Card.Title>
                                        <Card.Text className='card-content'>
                                            <p> It is simple to choose specific text from the PDF and condense it into a summary that emphasizes the crucial information.



                                            </p>
                                        </Card.Text>
                                        <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/sumicon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card-style'>
                                    <Card.Body>
                                        <Card.Title className='px-0 card-title-style'>
                                            <p className='card-tag'>Text</p>
                                            <p className='card-heading'>Parsing articles</p>
                                        </Card.Title>
                                        <Card.Text className='mb-2 card-content'>
                                            <p>ResearchTube.Ai dissects your research publication into various sections and extracts all the information from each section.



                                            </p>
                                        </Card.Text>
                                        <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/parseIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card-style'>
                                    <Card.Body>
                                        <Card.Title className='px-0 card-title-style'>
                                            <p className='card-tag'>Text</p>
                                            <p className='card-heading'>Figures extraction</p>
                                        </Card.Title>
                                        <Card.Text className='card-content'>
                                            <p> The artificial intelligence engine will identify and extract all the figures from your research publication, giving you the opportunity to choose which figures you wish to incorporate into your content.


                                            </p>
                                        </Card.Text>
                                        <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/extractIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className=' mt-md-4 third-sec-cards'>
                                <Card className='card-style'>
                                    <Card.Body>
                                        <Card.Title className='px-0 card-title-style'>
                                            <p className='card-tag'>Video</p>
                                            <p className='card-heading'>Auto-generate video</p>
                                        </Card.Title>
                                        <Card.Text className='card-content'>
                                            <p>   Within a matter of seconds, our artificial intelligence engine will merge the condensed text, voiceover, and chosen figures to generate a video for your research publication.


                                            </p>
                                        </Card.Text>

                                        <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/generateIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card-style'>
                                    <Card.Body>
                                        <Card.Title className='px-0 card-title-style'>
                                            <p className='card-tag'>Share</p>
                                            <p className='card-heading'>Share with our community</p>
                                        </Card.Title>
                                        <Card.Text className='card-content'>
                                            <p> Distribute your content to millions of researchers worldwide who will have free access to view and engage with it on our platform by commenting and initiating channels of communication.

                                            </p>
                                        </Card.Text>
                                        <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/shareIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='forth-sec-container'>
                    <div className='pt-5 forth-sec'>
                        <div className='forth-sec-t-container'>
                            <p className='forth-sec-t'>Share with our community of researchers.</p>
                            <p className='forth-sce-cont'>
                                {/* Commend your research breakthroughs with millions 
                                of researchers worldwide, which will raise awareness 
                                about your research publications and establish communication channels. */}
                                Commend your research breakthroughs with
                                millions of researchers worldwide, which will raise awareness
                                about your research publications and establish communication channels.
                            </p>
                        </div>
                        <div className='share-img-container'><img className='share-img' src='/assets/community.png' />
                        </div>
                    </div>
                </div>
                <div className='fifth-sec-container'>
                    <div className='pt-5 mt-5 fifth-sec'>
                        {/* <p className='fifth-sce-t'>Researchers all over the world trust ResearchTube.Ai</p> */}
                        <div className='m-0 py-4 flex-column flex-md-row align-items-center stat-container'>
                            <p className='fifth-sec-t2'>"ResearchTube.Ai aims to ensure that all research is given attention and that the research process is as efficient as possible." - Team</p>
                            {/* {/* <div className='d-flex flex-column justify-content-center align-items-center'>
                                <p className='stat1'>2.5m</p>
                                <p className='stat1-text'>Research articles.</p>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <p className='stat1'>30m</p>
                                <p className='stat1-text'> Researchers.</p>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <p className='stat1'>1.2M</p>
                                <p className='stat1-text'>TBD</p>
                            </div> */}

                        </div>
                    </div>
                </div>
                {/* <div className='sixth-sec-container'>
                    <div className='sixth-sec'>
                        <div className='mx-4 pt-3 pb-4'>
                            {/* <div className='mt-3 rate-header'>
                                <div className='col-lg-10'><img className='rate-comma' src='/assets/rateComma.png' /></div>
                                <div className='col-lg-auto'>
                                    <p className='p-0 m-0 rate-name'>Jaydon Bator</p>
                                    <p className='rate-place'>University of Toronto</p>
                                </div>
                            </div>
                            <div className='rate-content'>
                                <p className='sixth-sec-t'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>

                            </div>
                            <div className='rate-footer'>
                                <img className='sixth-sec-img-style' src='/assets/rateimg.png' />
                            </div> 
                            <FeedBackScroller />
                        </div>
                    </div>
                </div> */}
                <div className='mt-5 seventh-sec-container'>
                    <div className='justify-content-center flex-column flex-md-row seventh-sec'>
                        <div className='ms-lg-5 px-4 px-lg-0 sign-content'>
                            <p className='sign-t'>Try ResearchTube.Ai Free</p>
                            <p className='sign-p'>Be a part of our academic community operated by artificial intelligence</p>
                            <div className='mail-box'>
                                <p className='mt-3 mail-box-t'>Enter your Email</p>
                                <button
                                    onClick={() => {
                                        //     navigate('/SignUp')
                                        handleShow()
                                    }}
                                    className='mail-box-btn'>Start</button>
                            </div>
                        </div>
                        <img className='w-50' src='/assets/sec-7Image.png' />
                    </div>
                </div>
                <div className='pt-5'>
                    <Footer />
                </div>
            </div>

        </div>
    )
}

export default Landing
