import '../css/Header.css'
import { useNavigate, NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { BiUser, BiLogOut, BiSearch } from 'react-icons/bi'
import { FaUserCircle } from 'react-icons/fa'
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import Search from './Main/Search/Search';
import Notifications from './Notifications';
import { BsStars } from 'react-icons/bs';
const env = require('../../config.env')

const Header = (props) => {
  const [userData, setuserData] = useState(null);
  const [profile_pic, setProfilePic] = useState(null);
  const [is_logout, setIs_logout] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (props.userdata.user)
      setuserData(props.userdata.user)
  }, [props.userdata.user])
  const handleLogout = () => {
    axios.get(`${env.SERVER_REQUEST_PATH}logout`)
      .catch(err => {
        props.catchErrors(err)
        console.error(`Error: ${err}`)
      })
    localStorage.removeItem("user-token");
    setIs_logout(Math.random())
  }

  return (
    <div className='header2-style'>
      <Navbar expand="lg" className="py-3 px-4 border-bottom w-100">
        <Navbar.Brand href="/home">
          <a className="navbar-brand logo-style" >
            <img src={env.SERVER_MEDIA_PATH + 'pageLogo.png'} alt="logo" className='logo-size' />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="d-flex flex-column flex-lg-row align-items-lg-center align-items-start gap-3 justify-content-between p-0 w-100">
            <div></div>

            <Search />

            <div className="text-end d-flex gap-3 align-items-center justify-content-end">
              <Button className="px-3 start-gen" onClick={() => { navigate('/generate') }}>START GENERATING
                <BsStars size={15} className='ms-2' />
              </Button>

              <Notifications />

              <div className='profile-img' onClick={() => { navigate('/profile/' + userData?.username) }}>
                {userData?.profile_img &&
                  <img src={env.SERVER_REQUEST_PATH + userData?.profile_img}
                    alt="user" className="rounded-circle" />
                }
                {!userData?.profile_img && <FaUserCircle size={30} color='6e757c' />}
              </div>

              <Button variant="light" onClick={handleLogout}>
                <BiLogOut size={20} className='text-danger' />
              </Button>

            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Header
