import '../../../css/PlayVideo.css'
import '../../../css/PlayVideoContent.css'
import axios from 'axios'
import VideoViewer from '../../../../SharedComponents/js/VideoViewer';
import VideoDetails from './VideoDetails';
import AddToPlaylist from '../AddToPlaylist'
import CommentsBox from '../Comments/CommentsBox'
import VideoMaterials from './VideoMaterials';
import RecentVideoList from './RecentVideoList';
import ShareVideo from '../ShareVideo'
import React, { useEffect, useState } from 'react'
import { AiFillLike } from 'react-icons/ai'
import { BsEye, BsHeart, BsCheck, BsShare, BsDownload, BsPlus } from 'react-icons/bs'
import { FaRegComment } from 'react-icons/fa'
import Button from 'react-bootstrap/Button';
const env = require('../../../../config.env')
const helpers = require('../../../../utilities/helpers')

const PlayVideoContent = (props) => {
  const [videoContent, getvideoContent] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [likes_no, set_likes_no] = useState(0)
  const [liked_video, setliked_video] = useState(false)
  const [saved_video, setsaved_video] = useState(false)
  const [showShareModal, setshowShareModal] = useState(false)
  const [showPlaylistModal, setshowPlaylistModal] = useState(false)
  const [v_content, setV_content] = useState()

  let videoData = props.videoDetails
  let video_link = videoData?.uri
  const share_loading = (flag) => {
    setIsLoading(flag)
  }
  const playlist_loading = (flag) => {
    setIsLoading(flag)
  }

  const on_like_video = () => {
    let toggle = !liked_video
    setliked_video(toggle)
    let headers = {}
    if (props.is_private)
      headers = { is_private: true }
    axios.post(`${env.SERVER_REQUEST_PATH}like`, { video_id: videoData.id, liked: toggle },
      { headers: headers })
      .then((response) => {
        set_likes_no(response.data.result)
      }).catch(err => {
        setliked_video(liked_video)
        props.catchErrors(err)
        console.error(`Error: ${err}`)
      })
  }

  const on_save_video = (saved) => {
    let headers = {}
    if (props.is_private)
      headers = { is_private: true }
    axios.post(`${env.SERVER_REQUEST_PATH}save`, { video_id: videoData.id, saved: saved },
      { headers: headers })
      .then((response) => {

      }).catch(err => {
        props.catchErrors(err)
        console.error(`Error: ${err}`)
      })
  }

  useEffect(() => {
    if (videoData) {
      setV_content(videoContent)
      set_likes_no(videoData.no_likes)

      let headers = {}
      if (props.is_private) {
        headers = { is_private: true }
      }
      axios.get(`${env.SERVER_REQUEST_PATH}getLikedSaved/${videoData.id}`, { headers: headers })
        .then((response) => {
          let record = response.data
          setliked_video(record.liked)
          setsaved_video(record.saved)
        }).catch(err => {
          props.catchErrors(err)
          console.error(`Error: ${err}`)
        })
    }
  }, [])


  return (
    <>
      <div className='row row-gap-4'>

        <div className='col-md-7'>
          <div className='viewer-container w-100'>
            <VideoViewer
              thumb={videoData?.thumb_url ? videoData.thumb_url : ''}
              subtitle={videoData?.subtitle ? videoData.subtitle : ''}
              link={video_link ? video_link : ''}
              type="play"
              videoLoaded={(video_content) => { getvideoContent(video_content) }}
            />

            <div className='d-flex flex-md-row flex-column gap-3 align-items-start align-items-md-center justify-content-between mx-0 p-3 w-100'>
              <div className='d-flex align-items-center stats-font'>
                <span className='me-2'><BsEye color='#999999' size={15} /> {helpers.kFormatter(videoData?.no_views)}{' '} </span>
                <span className='mx-2'><FaRegComment color='#999999' size={15} /> {helpers.kFormatter(videoData?.no_comments)}{' '} </span>
                <span className='mx-2 link-pointer' onClick={on_like_video}>
                  {
                    liked_video ?
                      <AiFillLike color='#5644DE' size={15} />
                      :
                      <AiFillLike color='#999999' size={15} />
                  }
                  {' '} <span className={liked_video ? 'liked' : 'not-linked'}>{helpers.kFormatter(likes_no)}</span>
                </span>
              </div>

              <div className='d-flex align-items-center gap-3'>
                {
                  saved_video ?
                    <Button className='py-1 video-btn' variant="outline-primary" size="sm" onClick={on_save_video}>Save <BsHeart color='#5644DE' /> </Button>
                    :
                    <Button className='py-1 video-btn' variant="primary" size="sm" onClick={on_save_video}>Saved <BsCheck color='#fff' /></Button>
                }

                <Button className='py-1 video-btn' variant="outline-primary" size="sm"
                  onClick={() => {
                    setshowShareModal(true)
                  }}
                  disabled={videoData?.statusid != 1 ? 'disabled' : ''}
                >
                  Share <BsShare color='#5644DE' />
                </Button>

                <Button className='py-1 video-btn' variant="outline-primary" size="sm"
                  href={v_content}
                  disabled={v_content ? '' : 'disabled'}
                >
                  Download <BsDownload color='#5644DE' />
                </Button>

                {/* <Button className='py-1 video-btn' variant="outline-primary" size="sm"
                  onClick={() => { setshowPlaylistModal(true) }}
                >
                  Add to Playlist <BsPlus color='#5644DE' />
                </Button> */}

              </div>
            </div>
          </div>

          <ShareVideo
            share_loading={(flag) => share_loading(flag)}
            isLoading={isLoading}
            video_url={window.location.href}
            video_id={videoData?.id}
            showModal={showShareModal}
            closeModal={() => { setshowShareModal(false) }} />

          <AddToPlaylist
            modal_loading={(flag) => playlist_loading(flag)}
            isLoading={isLoading}
            video_id={videoData?.id}
            showModal={showPlaylistModal}
            showInfo={(msg) => { props.showInfo(msg) }}
            closeModal={() => { setshowPlaylistModal(false) }} />

        </div>

        <div className='col-md-5'>
          <VideoDetails is_private={props.is_private} videoDetails={videoData}
            videoContent={videoContent}
            share_loading={share_loading}
            playlist_loading={playlist_loading}
            isLoading={isLoading}
            showInfo={(msg) => { props.showInfo(msg) }}
            catchErrors={(err) => {
              props.catchErrors(err)
            }}
          />
          {/* <div className='row videoMaterial_div'>
              <VideoMaterials is_private={props.is_private} video_id={videoData ? videoData.id : ''} />
            </div> */}
        </div>

        <div className='col-12'>
          <CommentsBox is_private={props.is_private} video_id={videoData ? videoData.id : ''}
            catchErrors={(err) => {
              props.catchErrors(err)
            }} />
          {/* {props.type == 'p' && <div className='col-md-4'>
            <RecentVideoList
              type='v'
              is_private={props.is_private} Videoid={videoData ? videoData.id : ''}
              catchErrors={(err) => {
                props.catchErrors(err)
              }}
            />
          </div>
          } */}
        </div>

        <div className='col-12'>
          <RecentVideoList is_private={props.is_private}
            type={props.type == 'p' ? 'p' : 'v'}
            Videoid={videoData ? videoData.id : ''}
            playlist_id={props.playlist_id}
            catchErrors={(err) => {
              props.catchErrors(err)
            }}
          />
        </div>
      </div>

    </>
  )

}

export default PlayVideoContent
