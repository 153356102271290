import React, { useState, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import ViewVideos from '../src/Main/Pages/ViewVideos'
import FeedPage from './Main/Pages/FeedPage'
import Home from './Main/Pages/Home'
import PlayVideo from '../src/Main/Pages/PlayVideo'
import SignUp from '../src/Authentication/Pages/SignUp'
import Login from '../src/Authentication/Pages/Login'
import GenerateVideo from './PDFGeneration/Pages/GenerateVideo'
import Profile from './Main/Pages/ProfileEdit';
import PrivateRoute from './PrivateRoute';
import ResetPassword from './Authentication/Pages/ResetPassword'
import ForgetPassword from './Authentication/Pages/ForgetPassword'
import AccessDenied from './SharedComponents/js/AccessDenied'
import ComingSoon from './SharedComponents/js/ComingSoon'
import Landing from './Main/Pages/Landing';
import ConfirmMail from './Authentication/Pages/ConfirmMail';
import SearchResult from './Main/Pages/SearchResults';
import PublicProfile from './Main/Pages/PublicProfile';
import GenerateAudio from './PDFGeneration/Pages/GenerateAudio';
import DocumentAudios from './Main/Pages/DocumentAudios';
import AudioFeeds from './Main/Pages/AudioFeeds';
import VideoPlaylists from './Main/Pages/VideoPlaylists';
import PreviewLink from './Main/Pages/PreviewLink';
import NotFound from './SharedComponents/js/NotFound';
import PrivateVideo from './SharedComponents/js/PrivateVideo';
import WebsiteTerms from './Main/Pages/WebsiteTerms';
import GenerateVideoClip from './PDFGeneration/Pages/GenerateVideoClip';
// import VideoBackgrounds from './PDFGeneration/Components/VideoBackgrounds';
const actionTypes = {
  SET_USER: "SET_USER"
};
const helper = require('./utilities/helpers')
const env = require('./config.env')

function App() {

  return (
    <div className="appcontainer">
      <Routes>
        {/* <Route path="/" element={<Navigate replace to="/Home" />} /> */}
        {/* <Route exact path="/landing" element={<Landing />} /> */}
        {/* <Route exact path="/mycontent" element={<PrivateRoute><ViewVideos /></PrivateRoute>} /> */}
        {/* <Route exact path="/audioplaylists" element={<PrivateRoute><AudioFeeds /></PrivateRoute>} /> */}
        {/* <Route exact path="/vplaylists" element={<PrivateRoute><VideoPlaylists /></PrivateRoute>} /> */}
        {/* <Route exact path="/Feeds/:id" element={<PrivateRoute><FeedPage /></PrivateRoute>} /> */}
        {/* <Route exact path="/video/:id" element={<PreviewLink />} /> */}
        {/* <Route exact path="/playlist/:id" element={<PrivateRoute><DocumentAudios /></PrivateRoute>} /> */}

        <Route path="/" element={<Landing />} />
        <Route exact path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route exact path="/video/:id" element={<PrivateRoute><PlayVideo /></PrivateRoute>} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/terms" element={<WebsiteTerms />} />
        <Route exact path="/forget-password" element={<ForgetPassword />} />
        <Route exact path="/reset-password/:id" element={<ResetPassword />} />
        <Route exact path="/confirm-email/:id" element={<ConfirmMail />} />
        <Route exact path="/generate" element={<PrivateRoute><GenerateVideoClip /></PrivateRoute>} />
        <Route exact path="/generate-audio" element={<PrivateRoute><GenerateAudio /></PrivateRoute>} />
        <Route exact path="/search" element={<PrivateRoute><SearchResult /></PrivateRoute>} />
        <Route exact path="/edit-profile/:id" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route exact path="/profile/:id" element={<PrivateRoute><PublicProfile /></PrivateRoute>} />
        <Route exact path="/access-denied" element={<AccessDenied />} />
        <Route exact path="/coming-soon" element={<ComingSoon />} />
        <Route exact path="/private-content" element={<PrivateVideo />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/canvas" element={<VideoBackgrounds />} /> */}
      </Routes>
    </div>

  );
}

export default App;
